import React from 'react';
import './Welcome.css';
import Header from "../Header";
import {Link} from "react-router-dom";
import logo_SONDAGE from "../../assets/SONDAGE.svg";
import logo_RECLAMATION from "../../assets/RECLAMATION.svg";
import logo_RightCom from "../../assets/logo.svg";

const Welcome = (props) => {
    return (
        <div>
            <Header company={props.company} companyData={props.companyData} isHome={true}/>
            <div>
                <div className="right-bdu-container">
                    <div className="right-bdu-header">
                        <div className="title">
                            Sélectionnez une option
                        </div>
                    </div>
                    <div className="right-bdu-content">
                        <div>
                            {props.formData.survey_link && <Link to="/survey">
                                <div className="right-bdu-product" style={{
                                    background: props.formData?.background_color || 'grey',
                                    color: props.formData?.text_color || 'grey'
                                }}>
                                    <div className="content">
                                        <div className="right-bdu-product-container">
                                            <img className="icon" src={logo_SONDAGE} alt="sondage"/>
                                            <span className="title">SONDAGE</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>}

                            {props.formData.support_link && <Link to="/bot">
                                <div className="right-bdu-product" style={{
                                    background: props.formData?.background_color || 'grey',
                                    color: props.formData?.text_color || 'grey'
                                }}>
                                    <div className="content">
                                        <div className="right-bdu-product-container">
                                            <img className="icon" src={logo_RECLAMATION} alt="reclamation"/>
                                            <span className="title">RECLAMATION</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>}
                            {!props.formData.survey_link && !props.formData.support_link && <div>
                                <p>Please Setup your menu via the mobile app.</p>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="right-bdu-footer-wrap">
                    <span className="title">A product of</span>
                    <img className="logo" src={logo_RightCom} alt="logo"/>
                </div>
            </div>
        </div>
    );
};

export default Welcome;