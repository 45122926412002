export default [
    {
        'tag': 'select',
        'type': 'text',
        'name': 'objet',
        "cf-conditional-category": "category_transfer",
        'cf-questions': "Choisissez l'objet de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Transfert International",
                "value": "subject_transfer_international",
            },
            {
                "tag": "option",
                "cf-label": "Transfert National",
                "value": "subject_transfer_local",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason',
        "cf-conditional-objet": "subject_transfer_international",
        'cf-questions': "Choisissez le motif de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Transfert reçu non effectif",
                "value": "Transfert_recu_non_effectif",
            },
            {
                "tag": "option",
                "cf-label": "Transfert émis non effectif",
                "value": "Transfert_émis_non_effectif",
            },
            {
                "tag": "option",
                "cf-label": "Double prélèvement",
                "value": "transfert_Double_prélèvement",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason',
        "cf-conditional-objet": "subject_transfer_local",
        'cf-questions': "Choisissez le motif de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Virement reçu non effectif",
                "value": "Virement_recu_non_effectif",
            },
            {
                "tag": "option",
                "cf-label": "Virement emis non effectif",
                "value": "Virement_emis_non_effectif",
            },
            {
                "tag": "option",
                "cf-label": "Double prélèvement",
                "value": "virement_Double_prélèvement",
            },
        ]
    },

]
