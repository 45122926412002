import logo from './logo.svg';
import './App.css';
import {Route, Routes, Switch} from "react-router-dom";
import Company from "./Company";

function App() {
    const hostname = window.location.hostname.split('.')[0]
    // console.log(hostname)
    return (
        <div className="App">
            <Company company={hostname}/>
        </div>
    );
}

export default App;
