export default [
    {
        'tag': 'select',
        'type': 'text',
        'name': 'objet',
        "cf-conditional-category": "category_customer_relationship",
        'cf-questions': "Choisissez l'objet de la requête ",
        "children": [ 
            {
                "tag": "option",
                "cf-label": "Accueil",
                "value": "subject_customer_relationship_home",
            },
            {
                "tag": "option",
                "cf-label": "Indisponibilité de gestionnaire",
                "value": "subject_customer_relationship_manager_unavailability",
            },
            {
                "tag": "option",
                "cf-label": "Méthode de travail",
                "value": "subject_customer_relationship_working_method",
            },
            {
                "tag": "option",
                "cf-label": "Environnement de travail",
                "value": "subject_customer_relationship_working_environment",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason',
        "cf-conditional-objet": "subject_customer_relationship_working_environment",
        'cf-questions': "Choisissez le motif de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Confort",
                "value": "reason_customer_relationship_comfort",
            },
            {
                "tag": "option",
                "cf-label": "Equipement",
                "value": "reason_customer_relationship_equipment",
            },
            {
                "tag": "option",
                "cf-label": "Espace",
                "value": "reason_customer_relationship_space",
            },
            {
                "tag": "option",
                "cf-label": "Accessibilité de l’agence",
                "value": "reason_customer_relationship_accessibility_the_agency",
            },
            {
                "tag": "option",
                "cf-label": "Horaires",
                "value": "reason_customer_relationship_schedule",
            },
        ]
    },
    

]