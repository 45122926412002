import React, {useState} from 'react';
import logoBDU from '../../assets/logo_bdu.png';
import rcLogo from '../../assets/rightcom.png';
import homeIcon from '../../assets/icons/home.png';
import './index.css';
import {
    useNavigate,
} from "react-router-dom";

export default function Header(props) {
    let {isHome} = props;
    const history = useNavigate()
    const goHome = () => {
        history('/')
    }

    return (
        <div>
            <div className="right-bdu-head-wrap">
                <div className="right-bdu-head-container">
                    {!isHome && (
                        <div onClick={() => goHome()} className="right-bdu-head-back-home-container">
                            <img src={homeIcon} alt="icon-home"/>
                        </div>
                    )}
                    <div className={'name-container'}>
                        {props.company === 'bdu' && <img className="logo" src={logoBDU} alt="logo"/>}
                        {props.company === 'rightcom' && <img className="logo" src={rcLogo} alt="logo" style={{height:40}}/>}
                        {props.company !== 'bdu' && props.company !== 'rightcom' && <h2>{props.companyData.name}</h2>}
                    </div>
                </div>
            </div>
        </div>
    )
}
