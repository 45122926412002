const sunu_banque_voccabulaire = {
    category: {
        en: "Catégorie",
        fr: "Catégorie"
    },
    objet: {
        en: "Objet",
        fr: "Objet"
    },
    reason: {
        en: "Raison",
        fr: "Raison"
    },
    sub_reason: {
        en: "Raison",
        fr: "Raison"
    },
    category_monetique: {
        en: "Monétique",
        fr: "Monétique"
    },
    category_transfer: {
        en: "Transfert",
        fr: "Transfert"
    },
    Transfert_recu_non_effectif: {
        en: "Transfert reçu non effectif",
        fr: "Transfert reçu non effectif"
    },
    Transfert_émis_non_effectif: {
        en: "Transfert émis non effectif",
        fr: "Transfert émis non effectif"
    },
    transfert_Double_prélèvement: {
        en: "Double prélèvement",
        fr: "Double prélèvement"
    },
    Virement_recu_non_effectif: {
        en: "Virement reçu non effectif",
        fr: "Virement reçu non effectif"
    },
    Virement_emis_non_effectif: {
        en: "Virement emis non effectif",
        fr: "Virement emis non effectif"
    },
    virement_Double_prélèvement: {
        en: "Double prélèvement",
        fr: "Double prélèvement"
    },

    category_banking_service: {
        en: "Service Bancaire",
        fr: "Service Bancaire"
    },
    category_customer_relationship: {
        en: "Relation Client",
        fr: "Relation Client"
    },
    subject_customer_relationship_home: {
        en: "Accueil",
        fr: "Accueil"
    },
    subject_customer_relationship_manager_unavailability: {
        en: "Indisponibilité du gestionnaire",
        fr: "Indisponibilité du gestionnaire"
    },
    subject_customer_relationship_working_method: {
        en: "Méthode de travail",
        fr: "Méthode de travail"
    },
    reason_customer_relationship_comfort: {
        en: "Confort",
        fr: "Confort"
    },
    reason_customer_relationship_equipment: {
        en: "Equipement",
        fr: "Equipement"
    },
    reason_customer_relationship_space: {
        en: "Espace",
        fr: "Espace"
    },
    reason_customer_relationship_schedule: {
        en: "Horaires",
        fr: "Horaires"
    },
    category_others: {
        en: "Autres",
        fr: "Autres"
    },

    subject_transfer_local: {
        en: "Transfert Local",
        fr: "Transfert Local"
    },

    subject_transfer_international: {
        en: "Transfert International",
        fr: "Transfert International"
    },

    reason_transfer_contestation_unpaid_bills_rejections: {
        en: "Contestation d'impayés ou de rejets",
        fr: "Contestation d'impayés ou de rejets"
    },


    subject_banking_service_account_transaction: {
        en: "Opération sur compte",
        fr: "Opération sur compte"
    },

    subject_banking_service_Check: {
        en: "Chèque et effets",
        fr: "Chèque et effets"
    },

    subject_banking_service_loan: {
        en: "Pret",
        fr: "Pret"
    },

    subject_banking_service_insurance: {
        en: "Assurance",
        fr: "Assurance"
    },

    subject_banking_Account_opening: {
        en: "Ouverture de compte",
        fr: "Ouverture de compte"
    },

    subject_banking_closing_account: {
        en: "Clôture de compte",
        fr: "Clôture de compte"
    },
    reason_banking_service_direct_debit_account_maintenance_fees: {
        en: "Prélèvement agios et autres frais de tenue de compte",
        fr: "Prélèvement agios et autres frais de tenue de compte"
    },
    reason_banking_service_cash_register_transaction_error: {
        en: "Erreur opération de caisse",
        fr: "Erreur opération de caisse"
    },
    reason_banking_service_processing_time_not_met: {
        en: "Délai de traitement non respecté",
        fr: "Délai de traitement non respecté"
    },
    reason_banking_service_unpaid: {
        en: "Impayés",
        fr: "Impayés"
    },
    reason_banking_service_contestation_unpaid_bills_or_rejections: {
        en: "Contestation d'impayés ou de rejets",
        fr: "Contestation d'impayés ou de rejets"
    },
    reason_banking_service_order_not_satisfied: {
        en: "Ordre non satisfait",
        fr: "Ordre non satisfait"
    },
    reason_banking_service_Double_prelevement_échéance: {
        en: "Double prélèvement échéance",
        fr: "Double prélèvement échéance"
    },
    reason_banking_service_Delais_de_traitement: {
        en: "Délais de traitement",
        fr: "Délais de traitement"
    },
    reason_banking_service_BANK_READY_course_of_RightBot_demo: {
        en: "Parcours PRET BANCAIRE du RightBot demo",
        fr: "Parcours PRET BANCAIRE du RightBot demo"
    },
    reason_banking_service_Erreur_taux_intérêt: {
        en: "Erreur taux d'intérêt",
        fr: "Erreur taux d'intérêt"
    },
    reason_banking_service_Autres: {
        en: "Autres",
        fr: "Autres"
    },
    reason_banking_service_Bancassurance: {
        en: "Bancassurance",
        fr: "Bancassurance"
    },
    reason_banking_service_Account_opening_Fraude: {
        en: "Fraude",
        fr: "Fraude"
    },
    reason_banking_service_Account_opening_Litige: {
        en: "Litige",
        fr: "Litige"
    },
    reason_banking_service_insurance_Double_prélevement: {
        en: "Prélèvement multiples",
        fr: "Prélèvement multiples"
    },
    reason_banking_service_insurance_Erreur_prélevement: {
        en: "Erreur prélèvement",
        fr: "Erreur prélèvement"
    },
    reason_banking_service_insurance_autres: {
        en: "Autres",
        fr: "Autres"
    },

    subject_debit_a_tord: {
        en: "Débit à tord",
        fr: "Débit à tord"
    },
    subject_autres_objet: {
        en: "Autre",
        fr: "Autre"
    },
    reason_bank_to_wallet: {
        en: "Bank to Wallet",
        fr: "Bank to Wallet"
    },
    reason_gab: {
        en: "GAB",
        fr: "GAB"
    },
    reason_achat: {
        en: "Achat",
        fr: "Achat"
    },
    reason_capture: {
        en: "Capture",
        fr: "Capture"
    },
    reason_disponibilite_carte: {
        en: "Disponibilité de la Carte",
        fr: "Disponibilité de la Carte"
    },
    reason_message_erreur: {
        en: "Message Erreur",
        fr: "Message Erreur"
    },
    sub_reason_Carte_non_livrée: {
        en: "Carte non livrée",
        fr: "Carte non livrée"
    },
    sub_reason_Fraudulent_Pick_up: {
        en: "Fraudulent, Pick up",
        fr: "Fraudulent, Pick up"
    },
    sub_reason_Code_PIN_expire: {
        en: "Code PIN expiré",
        fr: "Code PIN expiré"
    },
    sub_reason_Dépassement_de_plafond: {
        en: "Dépassement de plafond",
        fr: "Dépassement de plafond"
    },
    sub_reason_Emetteur_indisponible: {
        en: "Émetteur indisponible",
        fr: "Émetteur indisponible"
    },
    sub_reason_Fond_insuffisant: {
        en: "Fond insuffisant",
        fr: "Fond insuffisant"
    },
    sub_reason_Fond_GAB_insuffisant: {
        en: "Fond GAB insuffisant",
        fr: "Fond GAB insuffisant"
    },
    sub_reason_A_ne_pas_honorer: {
        en: "A ne pas honorer",
        fr: "A ne pas honorer"
    },

    sub_reason_sunu_banqueci_orange: {
        en: "Sunu Banque vers Orange Money",
        fr: "Sunu Banque vers Orange Money"
    },
    sub_reason_orange_sunu_banqueci: {
        en: "Orange Money ver Sunu Banque",
        fr: "Orange Money ver Sunu Banque"
    },
    sub_reason_tpe: {
        en: "TPE",
        fr: "TPE"
    },
    sub_reason_en_ligne: {
        en: "En Ligne",
        fr: "En Ligne"
    },
    form_bank_wallet: {
        en: "Formulaire Bank Wallet",
        fr: "Formulaire Bank Wallet"
    },
    form_magnetique: {
        en: "Formulaire Carte Magnetique",
        fr: "Formulaire Carte Magnetique"
    },
    sub_reason_retrait: {
        en: "Retrait",
        fr: "Retrait"
    },
    sub_reason_virement: {
        en: "Virement",
        fr: "Virement"
    },
    sub_reason_bank_confrere: {
        en: "Banque Confrere",
        fr: "Banque Confrere"
    },
    sub_reason_nom_bank_confrere: {
        en: "Nom de la Banque",
        fr: "Nom de la Banque"
    },
    sub_reason_sunu_banqueci: {
        en: "Sunu Banque",
        fr: "Sunu Banque"
    },
    details_de_la_reclamation: {
        en: "Détail de la réclamation",
        fr: "Détail de la réclamation"
    },
    sub_reason_agence_sunu_banqueci: {
        en: "Agence Sunu Banque",
        fr: "Agence Sunu Banque"
    },
    sub_reason_reason_capture_sunu_banquegab_agence: {
        en: "Agence Sunu Banque",
        fr: "Agence Sunu Banque"
    },
    sub_reason_agence_confrere: {
        en: "Agence Confrère",
        fr: "Agence Confrère"
    },
    sub_reason_raison_bille_avale: {
        en: "Billet Avalé",
        fr: "Billet Avalé"
    },
    sub_reason_raison_cache_non_livre: {
        en: "Cash Non Livré",
        fr: "Cash Non Livré"
    },
    form_field_nom: {
        en: "",
        fr: ""
    },
    form_field_prenom: {
        en: "",
        fr: ""
    },
    form_field_telephone: {
        en: "",
        fr: ""
    },
    form_field_agence: {
        en: "",
        fr: ""
    },
    form_field_numero_compte: {
        en: "",
        fr: ""
    },
    form_field_type_carte: {
        en: "",
        fr: ""
    },
    form_field_numero_cart: {
        en: "",
        fr: ""
    },
    form_field_date_transaction: {
        en: "",
        fr: ""
    },
    form_field_heure_transaction: {
        en: "",
        fr: ""
    },
    form_field_montant_transaction: {
        en: "",
        fr: ""
    },
    form_field_destinataire_transaction: {
        en: "",
        fr: ""
    },
    form_field_numero_orange: {
        en: "",
        fr: ""
    },
    form_field_reference: {
        en: "",
        fr: ""
    },
    subject_customer_relationship_working_environment: {
        fr: "Environnement de Travail",
        en: "Environnement de Travail"
    },
    reason_customer_relationship_accessibility_the_agency: {
        fr: "Accessibilité de l'Agence",
        en: "Accessibilité de l'Agence"
    },
    reason_transfer_transfer_not_effective: {
        fr:"Viement Non Effectif",
        en:"Viement Non Effectif"
    },
    reason_transfer_double_direct_debit: {
        fr:"Double prélèvement",
        en:"Double prélèvement"
    },
    reason_transfer_transfers_not_effective: {
        fr:"Transferts non effectifs",
        en:"Transferts non effectifs"
    },
    sub_reason_mise_a_disposition: {
        en: "Mise à Disposition",
        fr: "Mise à Disposition"
    },
    reason_banking_service_Conditions_banque: {
        en: "Conditions de banque",
        fr: "Conditions de banque"
    },
    reason_banking_service_Ouverture_compte: {
        en: "Ouverture de compte",
        fr: "Ouverture de compte"
    },
    reason_banking_service_Clôture_compte: {
        en: "Clôture de compte",
        fr: "Clôture de compte"
    },
    reason_banking_service_Rejet_valeurs: {
        en: "Rejet de valeurs",
        fr: "Rejet de valeurs"
    },
    reason_banking_service_Impayés: {
        en: "Impayés",
        fr: "Impayés"
    },
    sub_reason_reason_capture_sunu_banquegab: {
        en: "Sunu Banque GAB",
        fr: "Sunu Banque GAB"
    },
    sub_reason_reason_capture_bank_confrere: {
        en: "Confrère",
        fr: "Confrère"
    },

}

export default sunu_banque_voccabulaire
