export default [
    ///////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-category": "category_others",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-category": "category_others",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-category": "category_others",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-category": "category_others",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-category": "category_others",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-category": "category_others",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-category": "category_others",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'montant',
        "cf-conditional-category": "category_others",
        'cf-questions': "Saisissez le montant (ou mettez RAS)",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'category_others_forFinishStep',
        "cf-conditional-category": "category_others",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-category_others_forFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ///////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-objet": "subject_demande_opposition",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-objet": "subject_demande_opposition",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-objet": "subject_demande_opposition",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-objet": "subject_demande_opposition",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-objet": "subject_demande_opposition",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'subject_demande_opposition_forFinishStep',
        "cf-conditional-objet": "subject_demande_opposition",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-subject_demande_opposition_forFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ///////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-objet": "subject_demande_carte_magnetique",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-objet": "subject_demande_carte_magnetique",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-objet": "subject_demande_carte_magnetique",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-objet": "subject_demande_carte_magnetique",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-objet": "subject_demande_carte_magnetique",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'subject_demande_carte_magnetiqueforFinishStep',
        "cf-conditional-objet": "subject_demande_carte_magnetique",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-subject_demande_carte_magnetiqueforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ///////////////////////
    /*
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_capture",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_capture",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_capture",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_capture",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_capture",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_captureforFinishStep',
        "cf-conditional-reason": "reason_capture",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_captureforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    */
    ///////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-sub_reason": "sub_reason_virement",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-sub_reason": "sub_reason_virement",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-sub_reason": "sub_reason_virement",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-sub_reason": "sub_reason_virement",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-sub_reason": "sub_reason_virement",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'sub_reason_virementforFinishStep',
        "cf-conditional-sub_reason": "sub_reason_virement",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-sub_reason_virementforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ///////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-sub_reason": "sub_reason_mise_a_disposition",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-sub_reason": "sub_reason_mise_a_disposition",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-sub_reason": "sub_reason_mise_a_disposition",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-sub_reason": "sub_reason_mise_a_disposition",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-sub_reason": "sub_reason_mise_a_disposition",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'sub_reason_mise_a_dispositionforFinishStep',
        "cf-conditional-sub_reason": "sub_reason_mise_a_disposition",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-sub_reason_mise_a_dispositionforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ///////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-category": "category_transfer",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-category": "category_transfer",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-category": "category_transfer",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-category": "category_transfer",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-category": "category_transfer",
        'cf-questions': "Saisissez le montant",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-category": "category_transfer",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-category": "category_transfer",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-category": "category_transfer",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'categorytransferforFinishStep',
        "cf-conditional-category": "category_transfer",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-categorytransferforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ///////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-category": "category_customer_relationship",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-category": "category_customer_relationship",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-category": "category_customer_relationship",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "category_customer_relationship",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-category": "category_customer_relationship",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-category": "category_customer_relationship",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-category": "category_customer_relationship",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'category_customer_relationshipforFinishStep',
        "cf-conditional-category": "category_customer_relationship",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-category_customer_relationshipforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Fraude",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Fraude",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Fraude",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Fraude",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Fraude",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Fraude",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Fraude",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_Account_opening_FraudeforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Fraude",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_Account_opening_FraudeforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Litige",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Litige",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Litige",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Litige",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Litige",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Litige",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Litige",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_Account_opening_LitigeforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Account_opening_Litige",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_Account_opening_LitigeforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_insurance_Double_prélevement",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_insurance_Double_prélevement",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_insurance_Double_prélevement",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_insurance_Double_prélevement",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_insurance_Double_prélevement",
        'cf-questions': "Saisissez le montant",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientTypeContrat',
        "cf-conditional-reason": "reason_banking_service_insurance_Double_prélevement",
        'cf-questions': "Veuillez choisir votre type de contrat",
        "children": [
            {
                "tag": "option",
                "cf-label": "EDUCASSUR",
                "value": "EDUCASSUR",
            },
            {
                "tag": "option",
                "cf-label": "Sunu Banque ASSUR",
                "value": "Sunu Banque ASSUR",
            },
            {
                "tag": "option",
                "cf-label": "RETRAITE COMPLEMENTAIRE",
                "value": "RETRAITE COMPLEMENTAIRE",
            },
            {
                "tag": "option",
                "cf-label": "Sunu Banque SECURITE FAMILLE",
                "value": "Sunu Banque SECURITE FAMILLE",
            },
            {
                "tag": "option",
                "cf-label": "Sunu Banque ASSISTANCE OBSEQUES",
                "value": "Sunu Banque ASSISTANCE OBSEQUES",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE VOYAGE",
                "value": "ASSURANCE VOYAGE",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE MULTI RISQUES HABITATIONS",
                "value": "ASSURANCE MULTI RISQUES HABITATIONS",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE MULTI RISQUES PROFESSIONNELLES",
                "value": "ASSURANCE MULTI RISQUES PROFESSIONNELLES",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE PLUS",
                "value": "ASSURANCE PLUS",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_insurance_Double_prélevement",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_insurance_Double_prélevement",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_insurance_Double_prélevement",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_insurance_Double_prélevementforFinishStep',
        "cf-conditional-reason": "reason_banking_service_insurance_Double_prélevement",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_insurance_Double_prélevementforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_insurance_Erreur_prélevement",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_insurance_Erreur_prélevement",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_insurance_Erreur_prélevement",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_insurance_Erreur_prélevement",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_insurance_Erreur_prélevement",
        'cf-questions': "Saisissez le montant",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientTypeContrat',
        "cf-conditional-reason": "reason_banking_service_insurance_Erreur_prélevement",
        'cf-questions': "Veuillez choisir votre type de contrat",
        "children": [
            {
                "tag": "option",
                "cf-label": "EDUCASSUR",
                "value": "EDUCASSUR",
            },
            {
                "tag": "option",
                "cf-label": "Sunu Banque ASSUR",
                "value": "Sunu Banque ASSUR",
            },
            {
                "tag": "option",
                "cf-label": "RETRAITE COMPLEMENTAIRE",
                "value": "RETRAITE COMPLEMENTAIRE",
            },
            {
                "tag": "option",
                "cf-label": "Sunu Banque SECURITE FAMILLE",
                "value": "Sunu Banque SECURITE FAMILLE",
            },
            {
                "tag": "option",
                "cf-label": "Sunu Banque ASSISTANCE OBSEQUES",
                "value": "Sunu Banque ASSISTANCE OBSEQUES",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE VOYAGE",
                "value": "ASSURANCE VOYAGE",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE MULTI RISQUES HABITATIONS",
                "value": "ASSURANCE MULTI RISQUES HABITATIONS",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE MULTI RISQUES PROFESSIONNELLES",
                "value": "ASSURANCE MULTI RISQUES PROFESSIONNELLES",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE PLUS",
                "value": "ASSURANCE PLUS",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_insurance_Erreur_prélevement",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_insurance_Erreur_prélevement",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_insurance_Erreur_prélevement",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_insurance_Erreur_prélevementforFinishStep',
        "cf-conditional-reason": "reason_banking_service_insurance_Erreur_prélevement",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_insurance_Erreur_prélevementforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_insurance_autres",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_insurance_autres",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_insurance_autres",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_insurance_autres",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientTypeContrat',
        "cf-conditional-reason": "reason_banking_service_insurance_autres",
        'cf-questions': "Veuillez choisir votre type de contrat",
        "children": [
            {
                "tag": "option",
                "cf-label": "EDUCASSUR",
                "value": "EDUCASSUR",
            },
            {
                "tag": "option",
                "cf-label": "Sunu Banque ASSUR",
                "value": "Sunu Banque ASSUR",
            },
            {
                "tag": "option",
                "cf-label": "RETRAITE COMPLEMENTAIRE",
                "value": "RETRAITE COMPLEMENTAIRE",
            },
            {
                "tag": "option",
                "cf-label": "Sunu Banque SECURITE FAMILLE",
                "value": "Sunu Banque SECURITE FAMILLE",
            },
            {
                "tag": "option",
                "cf-label": "Sunu Banque ASSISTANCE OBSEQUES",
                "value": "Sunu Banque ASSISTANCE OBSEQUES",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE VOYAGE",
                "value": "ASSURANCE VOYAGE",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE MULTI RISQUES HABITATIONS",
                "value": "ASSURANCE MULTI RISQUES HABITATIONS",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE MULTI RISQUES PROFESSIONNELLES",
                "value": "ASSURANCE MULTI RISQUES PROFESSIONNELLES",
            },
            {
                "tag": "option",
                "cf-label": "ASSURANCE PLUS",
                "value": "ASSURANCE PLUS",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_insurance_autres",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_insurance_autres",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_insurance_autres",
        'cf-questions': "Saisissez le montant (ou mettez RAS)",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_insurance_autres",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_insurance_autresforFinishStep',
        "cf-conditional-reason": "reason_banking_service_insurance_autres",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_insurance_autresforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Delais_de_traitement",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Delais_de_traitement",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_Delais_de_traitement",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Delais_de_traitement",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_Delais_de_traitement",
        'cf-questions': 'Saisissez le montant',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Delais_de_traitement",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Delais_de_traitement",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Delais_de_traitement",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de la demande, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_Delais_de_traitementforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Delais_de_traitement",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_Delais_de_traitementforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Double_prelevement_échéance",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Double_prelevement_échéance",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_Double_prelevement_échéance",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Double_prelevement_échéance",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_Double_prelevement_échéance",
        'cf-questions': 'Saisissez le montant',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Double_prelevement_échéance",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Double_prelevement_échéance",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Double_prelevement_échéance",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_Double_prelevement_échéanceforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Double_prelevement_échéance",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_Double_prelevement_échéanceforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Erreur_taux_intérêt",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Erreur_taux_intérêt",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_Erreur_taux_intérêt",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Erreur_taux_intérêt",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_Erreur_taux_intérêt",
        'cf-questions': 'Saisissez le montant',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Erreur_taux_intérêt",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Erreur_taux_intérêt",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Erreur_taux_intérêt",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_Erreur_taux_intérêtforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Erreur_taux_intérêt",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_Erreur_taux_intérêtforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Autres",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Autres",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_Autres",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Autres",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Autres",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Autres",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_Autres",
        'cf-questions': "Saisissez le montant (ou mettez RAS)",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Autres",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_AutresforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Autres",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_AutresforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_contestation_unpaid_bills_or_rejections",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_contestation_unpaid_bills_or_rejections",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_contestation_unpaid_bills_or_rejections",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_contestation_unpaid_bills_or_rejections",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_contestation_unpaid_bills_or_rejections",
        'cf-questions': "Saisissez le montant",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_contestation_unpaid_bills_or_rejections",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_contestation_unpaid_bills_or_rejections",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_contestation_unpaid_bills_or_rejections",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de la remise, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_contestation_unpaid_bills_or_rejectionsforFinishStep',
        "cf-conditional-reason": "reason_banking_service_contestation_unpaid_bills_or_rejections",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_contestation_unpaid_bills_or_rejectionsforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    //////////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_order_not_satisfied",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_order_not_satisfied",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_order_not_satisfied",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_order_not_satisfied",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_order_not_satisfied",
        'cf-questions': "Saisissez le montant",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'dateRemise',
        "cf-conditional-reason": "reason_banking_service_order_not_satisfied",
        'cf-questions': "Date de remise ?",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_order_not_satisfied",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_order_not_satisfied",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_order_not_satisfied",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de la remise, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_order_not_satisfiedforFinishStep',
        "cf-conditional-reason": "reason_banking_service_order_not_satisfied",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_order_not_satisfiedforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    /////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Conditions_banque",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Conditions_banque",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_Conditions_banque",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Conditions_banque",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Conditions_banque",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Conditions_banque",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Conditions_banque",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_Conditions_banqueforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Conditions_banque",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_Conditions_banqueforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_direct_debit_account_maintenance_fees",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_direct_debit_account_maintenance_fees",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_direct_debit_account_maintenance_fees",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_direct_debit_account_maintenance_fees",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_direct_debit_account_maintenance_fees",
        'cf-questions': "Saisissez le montant",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_direct_debit_account_maintenance_fees",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_direct_debit_account_maintenance_fees",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_direct_debit_account_maintenance_fees",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_direct_debit_account_maintenance_feesforFinishStep',
        "cf-conditional-reason": "reason_banking_service_direct_debit_account_maintenance_fees",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_direct_debit_account_maintenance_feesforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_cash_register_transaction_error",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_cash_register_transaction_error",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_cash_register_transaction_error",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_cash_register_transaction_error",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_cash_register_transaction_error",
        'cf-questions': "Saisissez le montant",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_cash_register_transaction_error",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_cash_register_transaction_error",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_cash_register_transaction_error",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_cash_register_transaction_errorforFinishStep',
        "cf-conditional-reason": "reason_banking_service_cash_register_transaction_error",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_cash_register_transaction_errorforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Ouverture_compte",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Ouverture_compte",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Ouverture_compte",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_Ouverture_compte",
        'cf-questions': "Saisissez le montant",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Ouverture_compte",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Ouverture_compte",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Ouverture_compte",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_Ouverture_compteforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Ouverture_compte",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_Ouverture_compteforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Clôture_compte",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Clôture_compte",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_Clôture_compte",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Clôture_compte",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Clôture_compte",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Clôture_compte",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Clôture_compte",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_Clôture_compteforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Clôture_compte",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_Clôture_compteforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Rejet_valeurs",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Rejet_valeurs",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_Rejet_valeurs",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Rejet_valeurs",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Rejet_valeurs",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Rejet_valeurs",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Rejet_valeurs",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_Rejet_valeursforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Rejet_valeurs",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_Rejet_valeursforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ////////////////////
    {
        'tag': 'input',
        'type': 'text',
        'name': 'lastname',
        "cf-conditional-reason": "reason_banking_service_Impayés",
        'cf-questions': 'Veuillez saisir votre nom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'firstname',
        "cf-conditional-reason": "reason_banking_service_Impayés",
        'cf-questions': 'Veuillez saisir votre prénom',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'accountNumber',
        "cf-conditional-reason": "reason_banking_service_Impayés",
        'cf-questions': 'Votre numero de compte (client Sunu Banque)',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'clientAgence',
        "cf-conditional-reason": "reason_banking_service_Impayés",
        'cf-questions': "Veuillez selectionner l'agence",
        "children": [
            {
                "tag": "option",
                "cf-label": "Agence 1",
                "value": "Agence 1",
            },
            {
                "tag": "option",
                "cf-label": "Agence 2",
                "value": "Agence 2",
            },
            {
                "tag": "option",
                "cf-label": "Agence 3",
                "value": "Agence 3",
            },
            {
                "tag": "option",
                "cf-label": "Agence 4",
                "value": "Agence 4",
            }
        ]
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'montant',
        "cf-conditional-reason": "reason_banking_service_Impayés",
        'cf-questions': "Saisissez le montant",
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'number',
        'name': 'phone',
        "cf-conditional-reason": "reason_banking_service_Impayés",
        'cf-questions': 'Veuillez saisir votre numéro de téléphone',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'input',
        'type': 'email',
        'name': 'email',
        "cf-conditional-reason": "reason_banking_service_Impayés",
        'cf-questions': 'Veuillez saisir votre adresse mail',
        'cf-input-placeholder': 'Entrez votre réponse ici...',
        'cf-error': 'Veuillez vérifier votre e-mail.'
    },
    {
        'tag': 'input',
        'type': 'text',
        'name': 'details_de_la_reclamation',
        "cf-conditional-reason": "reason_banking_service_Impayés",
        'cf-questions': "Veuillez fournir des détails sur la réclamation",
        'cf-input-placeholder': '(Date de l’opération, informations complémentaires…)',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason_banking_service_ImpayésforFinishStep',
        "cf-conditional-reason": "reason_banking_service_Impayés",
        'cf-questions': "Avez-vous des fichiers à joindre ? ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Oui",
                "value": "sunu_banque_bot_upload_file_yes",
            },
            {
                "tag": "option",
                "cf-label": "Non",
                "value": "sunu_banque_bot_upload_file_no",
            },
        ]
    },
    {
        'tag': 'input',
        'type': 'file',
        'multiple': true,
        'name': 'files',
        "cf-conditional-reason_banking_service_ImpayésforFinishStep": "sunu_banque_bot_upload_file_yes",
        'cf-questions': 'Cliquez sur le bouton ci-dessous pour ajouter les fichiers',
        'cf-validation': 'required',
        'cf-error': 'Cette information est requise.'
    },
    ///////////////////////////////

]
