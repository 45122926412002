const constants = {
    dev: {
        rightDeskUrl:"https://rightcareapi.sunubank.tg",
        accountClientUrl:"https://accapi-v1.sunubank.tg",
        adminApiUrl:"https://adminapi-v1.sunubank.tg",
        cdnUrl:"https://rccdn.sunubank.tg",
        cdnToken:"Dcfb51409eb9f42f8b87e2f0248573f03D",
    },
    prod: {
        rightDeskUrl:"https://rightcareapi.sunubank.tg",
        accountClientUrl:"https://accapi-v1.sunubank.tg",
        adminApiUrl:"https://adminapi-v1.sunubank.tg",
        cdnUrl:"https://rccdn.sunubank.tg",
        cdnToken:"Dcfb51409eb9f42f8b87e2f0248573f03D",
    }
}

export default constants