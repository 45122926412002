export default [
    {
        'tag': 'select',
        'type': 'text',
        'name': 'objet',
        "cf-conditional-category": "category_banking_service",
        'cf-questions': "Choisissez l'objet de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Opération sur compte",
                "value": "subject_banking_service_account_transaction",
            },
            {
                "tag": "option",
                "cf-label": "Chèque et effets",
                "value": "subject_banking_service_Check",
            },
            {
                "tag": "option",
                "cf-label": "Prêt",
                "value": "subject_banking_service_loan",
            },
            {
                "tag": "option",
                "cf-label": "Assurance",
                "value": "subject_banking_service_insurance",
            },
            {
                "tag": "option",
                "cf-label": "Autres",
                "value": "subject_banking_Account_opening",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason',
        "cf-conditional-category": "subject_banking_service_account_transaction",
        'cf-questions': "Choisissez le motif de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Conditions de banque",
                "value": "reason_banking_service_Conditions_banque",
            },
            {
                "tag": "option",
                "cf-label": "Prélèvement agios et autres frais de tenue de compte",
                "value": "reason_banking_service_direct_debit_account_maintenance_fees",
            },
            {
                "tag": "option",
                "cf-label": "Erreur opération de caisse",
                "value": "reason_banking_service_cash_register_transaction_error",
            },
            {
                "tag": "option",
                "cf-label": "Ouverture de compte",
                "value": "reason_banking_service_Ouverture_compte",
            },
            {
                "tag": "option",
                "cf-label": "Clôture de compte",
                "value": "reason_banking_service_Clôture_compte",
            },
            {
                "tag": "option",
                "cf-label": "Rejet de valeurs",
                "value": "reason_banking_service_Rejet_valeurs",
            },
            {
                "tag": "option",
                "cf-label": "Impayés",
                "value": "reason_banking_service_Impayés",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason',
        "cf-conditional-category": "subject_banking_service_Check",
        'cf-questions': "Choisissez le motif de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Contestation d'impayés ou de rejets",
                "value": "reason_banking_service_contestation_unpaid_bills_or_rejections",
            },
            {
                "tag": "option",
                "cf-label": "Ordre non satisfait",
                "value": "reason_banking_service_order_not_satisfied",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason',
        "cf-conditional-category": "subject_banking_service_loan",
        'cf-questions': "Choisissez le motif de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Delais de traitement",
                "value": "reason_banking_service_Delais_de_traitement",
            },
            {
                "tag": "option",
                "cf-label": "Double prelevement échéance",
                "value": "reason_banking_service_Double_prelevement_échéance",
            },
            {
                "tag": "option",
                "cf-label": "Erreur taux d'intérêt",
                "value": "reason_banking_service_Erreur_taux_intérêt",
            },
            {
                "tag": "option",
                "cf-label": "Autres",
                "value": "reason_banking_service_Autres",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason',
        "cf-conditional-category": "subject_banking_service_insurance",
        'cf-questions': "Choisissez le motif de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Prélèvement multiples",
                "value": "reason_banking_service_insurance_Double_prélevement",
            },
            {
                "tag": "option",
                "cf-label": "Erreur prélèvement",
                "value": "reason_banking_service_insurance_Erreur_prélevement",
            },
            {
                "tag": "option",
                "cf-label": "Autres",
                "value": "reason_banking_service_insurance_autres",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason',
        "cf-conditional-objet": "subject_banking_Account_opening",
        'cf-questions': "Choisissez le motif de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Fraude",
                "value": "reason_banking_service_Account_opening_Fraude",
            },
            {
                "tag": "option",
                "cf-label": "Litige",
                "value": "reason_banking_service_Account_opening_Litige",
            },
        ]
    },



]
