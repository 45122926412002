import React, { useState, useEffect, useRef } from 'react';
import { form_flow } from '../userflows/fr';
import axios from "axios";
import { ConversationalForm as CForm } from 'conversational-form';
import './ConversationalForm.css';
import RightBot from '../../assets/RightBot.png';
import Avatar from '../../assets/avatar.png';
import qs from "qs";
import fileExtension from "file-extension"
//import { manUser } from '../../assets/man-user.svg';
import {
  useNavigate,
} from "react-router-dom";
import useRightDesk from "../useRightDesk";

import InputMask from "react-input-mask";
import constants from "../../constants";

const defaultFlow = [{
  'tag': 'cf-robot-message',
  'name': 'welcome',
  'cf-questions': 'Bienvenue!',
},{
  'tag': 'cf-robot-message',
  'name': 'welcome',
  'cf-questions': 'Ceci est une demo.',
}]

let xcf = null;
const ConversationalFormSunuBanque = (props) => {

  const rightdesk = useRightDesk()
  const [submited, setSubmited] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [cats, setCats] = React.useState([])

  const history = useNavigate()

  const elem = useRef();
  let cf = null;
  let standardFiles = []

  const [formFields, setFormFields] = useState(form_flow);
  const [isFinishBot, setIsFinishBot] = useState(false)
  const [isValidSubmitForm, setIsValidSubmitForm] = useState(false)
  const [finishBotForm, setFinishBotForm] = useState({})
  const [isFileLoading, setIsFileLoading] = useState(false)
  const [errorMsgFileUpload, setErrorMsgFileUpload] = useState("")
  const [successMsgFileUpload, setSuccessMsgFileUpload] = useState("")

  const [isWalletForm, setIsWalletForm] = useState(false)
  const [errorsForm, setErrorsForm] = useState({
    lastname: "",
    firstname: "",
    phone: "",
    email: "",
    agency: "",
    accountNumber: "",
    orangeMoneyNumber: "",
    transactionRef: "",
    typeCard: "",
    cardNumber: "",
    transactionDate: "",
    transactionTime: "",
    transactionAmount: "",
    transactionLocation: "",
  })
  const [formWalletForm, setFormWalletForm] = useState({
    lastname: "",
    firstname: "",
    phone: "",
    email: "",
    agency: "",
    accountNumber: "",
    orangeMoneyNumber: "",
    transactionDate: "",
    transactionAmount: "",
    transactionRef: "",
    files: [],
  })

  const [isMagnetiqueForm, setIsMagnetiqueForm] = useState(false)
  const [errorsMagnetiqueForm, setErrorsMagnetiqueForm] = useState({})
  const [formMagnetiqueForm, setFormMagnetiqueForm] = useState({
    lastname: "",
    firstname: "",
    agency: "",
    phone: "",
    email: "",
    accountNumber: "",
    typeCard: "",
    cardNumber: "",
    transactionDate: "",
    transactionTime: "",
    transactionAmount: "",
    transactionLocation: "",
    files: [],
  })

  const handleFieldWalletChange = async (e, name) => {
    let value
    let typesFilesAuthorize = ["JPG", "JPEG", "PNG", "PDF", "DOC", "DOCX", "TXT", "CSV", "XLS", "XLSX"]

    if(name == "files") {
      setErrorMsgFileUpload(" ")
      setSuccessMsgFileUpload(" ")
      setIsFileLoading(true)
      let files = [...e.target.files]

      if(files.length > 3) { // 3 fichiers max
        setErrorMsgFileUpload("Vous pouvez télécharger maximum 3 fichiers")
      } else {
        let promises = []

        files.map((file) => {
          promises.push((async (file) => {
            if(file.size > 1000000) { // < 10Mo de capacité
              setErrorMsgFileUpload("La taille du fichier dépasse 10Mo")
              return
            }
            if(!(typesFilesAuthorize.indexOf(fileExtension(file.name).toUpperCase()) > -1)) { // Si le type de fichier n'est pas dans la liste
              setErrorMsgFileUpload("Ce type de fichier n'est pas autorisé")
              return
            }

            let formdata = new FormData()
            formdata.append("file", file);
            formdata.append("file_type", file.type);
            formdata.append("app_name", " rightcare");

            // Send file to CDN
            let backFileData = await uploadFileCDN(formdata)
            // console.log('files after uploaded...', backFileData.file_id)

            file.file_id = backFileData.file_id
          })(file))
        })

        await Promise.all(promises)
      }
      setIsFileLoading(false)

      value = []
      files.forEach(el => {
        if(el.file_id) {
          value.push(el.file_id)
        }
      })
      // console.log(files, value)

      if(files.length === value.length) {
        setSuccessMsgFileUpload(`${value.length} fichier(s) téléchargé(s) avec succès`)
      } else {
        value = []
      }
    } else {
      value = e.target.value
    }

    setFormWalletForm({ ...formWalletForm, [name]: value })
    // console.log("result", formWalletForm)
  }

  const handleFieldMagnetiqueChange = async (e, name) => {
    let value
    let typesFilesAuthorize = ["JPG", "JPEG", "PNG", "PDF", "DOC", "DOCX", "TXT", "CSV", "XLS", "XLSX"]

    if(name == "files") {
      setErrorMsgFileUpload(" ")
      setSuccessMsgFileUpload(" ")
      setIsFileLoading(true)
      let files = [...e.target.files]

      if(files.length > 3) { // 3 fichiers max
        setErrorMsgFileUpload("Vous pouvez télécharger maximum 3 fichiers")
      } else {
        let promises = []

        files.map((file) => {
          promises.push((async (file) => {
            if(file.size > 1000000) { // < 10Mo de capacité
              setErrorMsgFileUpload("La taille du fichier dépasse 10Mo")
              return
            }
            if(!(typesFilesAuthorize.indexOf(fileExtension(file.name).toUpperCase()) > -1)) { // Si le type de fichier n'est pas dans la liste
              setErrorMsgFileUpload("Ce type de fichier n'est pas autorisé")
              return
            }

            let formdata = new FormData()
            formdata.append("file", file);
            formdata.append("file_type", file.type);
            formdata.append("app_name", " rightcare");

            // Send file to CDN
            let backFileData = await uploadFileCDN(formdata)
            // console.log('files after uploaded...', backFileData.file_id)

            file.file_id = backFileData.file_id
          })(file))
        })

        await Promise.all(promises)
      }
      setIsFileLoading(false)

      value = []
      files.forEach(el => {
        if(el.file_id) {
          value.push(el.file_id)
        }
      })
      // console.log(files, value)

      if(files.length === value.length) {
        setSuccessMsgFileUpload(`${value.length} fichier(s) téléchargé(s) avec succès`)
      } else {
        value = []
      }
    } else {
      value = e.target.value
    }

    setFormMagnetiqueForm({ ...formMagnetiqueForm, [name]: value })
    // console.log("result", formMagnetiqueForm)
  }

  //var inputField = document.getElementsByClassName("inputWrapper");
  //var fourChildNode = inputField[0].childNodes[1];
  //fourChildNode.placeholder = "Tapez votre réponse ici ...";
  const uploadFileCDN = (data) => {
    return axios.post(constants.prod.cdnUrl + "/api/1.0/upload/normal", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": ' '+constants.prod.cdnToken,
      },
    }).then((result) => {
        // console.log("Success Upload file to CDN...", result)
        return result.data.data.file_details
      })
      .catch((error) => {
        // console.log("Error Upload file to CDN", error)
        return { status: 400, error: error };
      })
  }
  useEffect(() => {
    if (isFinishBot) {
      if (isWalletForm) {
        checkValidWalletForm()
      }
      if (isMagnetiqueForm) {
        checkValidMagnetiqueForm()
      }
    } else {
      cf = CForm.startTheConversation({
        options: {
          submitCallback: submitData,
          theme: 'RightCom',
          preventAutoFocus: true,
          hideUserInputOnNoneTextInput: true,
          // loadExternalStyleSheet: false
          loadExternalStyleSheet: false,
          userImage: Avatar,
          robotImage: RightBot,
          flowStepCallback: async (dto, success, error) => {
            // console.log("this is the dto ---> ", dto)

            if (dto.tag.name == "name") {
              if (dto.tag.value.toLowerCase() !== "") {
                return success();
              } else {
                return error();
              }
              //conversationalForm.stop("Stopping form, but added value");
            }
            if (dto.tag.name == "lastname") {
              if (dto.tag.value.toLowerCase() !== "") {
                return success();
              } else {
                return error();
              }
              //conversationalForm.stop("Stopping form, but added value");
            }
            if (dto.tag.name == "firstname") {
              if (dto.tag.value.toLowerCase() !== "") {
                return success();
              } else {
                return error();
              }
              //conversationalForm.stop("Stopping form, but added value");
            }
            if (dto.tag.name == "phone") {
              if (dto.tag.value.toLowerCase() !== "") {
                return success();
              } else {
                return error();
              }
              //conversationalForm.stop("Stopping form, but added value");
            }
            if (dto.tag.name == "email") {
              if (dto.tag.value.toLowerCase() !== "") {
                return success();
              } else {
                return error();
              }
              //conversationalForm.stop("Stopping form, but added value");
            }

            else if (dto.tag.name == "email") {
              if (dto.tag.value.toLowerCase() !== "") {
                return success();
              } else {
                return error();
              }
            }
            else if (dto.tag.name == "mobile") {
              // To define the length of the phone number, set to 8 for Benin and 10 for Ivory Coast
              if (dto.tag.value.toLowerCase() !== "") {
                // console.log("this is dto", dto.text.length)
                // console.log("this is dto", dto.tag.value.length)
                return success();
              } else {
                return error();
              }
            }
            else if (dto.tag.name == "comment") {
              if (dto.tag.value.toLowerCase() !== "") {
                return success();
              } else {
                return error();
              }
            }

            if (dto.tag.name == "files") {
              //cf?.addRobotChatResponse("Fichier(s) en cours de téléchargement...")

              let value = []
              let typesFilesAuthorize = ["JPG", "JPEG", "PNG", "PDF", "DOC", "DOCX", "TXT", "CSV", "XLS", "XLSX"]

              let files = [...dto.controlElements[0]._files]

              if(files.length > 3) { // 3 fichiers max
                cf?.addRobotChatResponse("Vous pouvez télécharger maximum 3 fichiers")
                return error()
              } else {
                let promises = []
                files.map((file) => {
                  promises.push((async (file) => {
                    if(file.size > 1000000) { // < 10Mo de capacité
                      cf?.addRobotChatResponse("La taille du fichier dépasse 10Mo")
                      return error()
                      return
                    }
                    if(!(typesFilesAuthorize.indexOf(fileExtension(file.name).toUpperCase()) > -1)) { // Si le type de fichier n'est pas dans la liste
                      cf?.addRobotChatResponse("Ce type de fichier n'est pas autorisé")
                      return error()
                      return
                    }

                    let formdata = new FormData()
                    formdata.append("file", file);
                    formdata.append("file_type", file.type);
                    formdata.append("app_name", " rightcare");

                    // Send file to CDN
                    let backFileData = await uploadFileCDN(formdata)
                    // console.log('files after uploaded...', backFileData.file_id)

                    file.file_id = backFileData.file_id
                  })(file))
                })

                await Promise.all(promises)

                files.forEach(el => {
                  if(el.file_id) {
                    value.push(el.file_id)
                  }
                })
                // console.log(value)
                if(files.length === value.length) {
                  standardFiles = value

                  cf?.addRobotChatResponse(`${value.length} fichier(s) téléchargé(s) avec succès`)
                } else {
                  return error()
                }
              }
            }

            return success();
          }
        },
        tags: props.formData.support_link ? formFields : defaultFlow,
      }
      )
      elem.current.appendChild(cf.el)
    }

  }, [formFields, formWalletForm, formMagnetiqueForm]);

  const submitData = () => {
    var formDataSerialized = cf.getFormData(true);

    formDataSerialized["language"] = "fr"
    if (Object.keys(formDataSerialized).indexOf("comment") === -1) {
      formDataSerialized["comment"] = ""
    }
    // si le process abouti à wallet form
    if (formDataSerialized.sub_reason && (
      formDataSerialized.sub_reason.indexOf("sub_reason_sunu_banqueci_orange") !== -1 ||
      formDataSerialized.sub_reason.indexOf("sub_reason_orange_sunu_banqueci") !== -1)) {
      setIsWalletForm(true)
      setIsFinishBot(true)
    }

    // si le process abouti à magnétique form
    if ((formDataSerialized.sub_reason || formDataSerialized.sub_reason_bank) && (
      formDataSerialized.sub_reason.indexOf("sub_reason_tpe") !== -1 ||
      formDataSerialized.sub_reason.indexOf("sub_reason_en_ligne") !== -1 ||
      formDataSerialized.sub_reason.indexOf("sub_reason_raison_bille_avale") !== -1 ||
      formDataSerialized.sub_reason.indexOf("sub_reason_raison_cache_non_livre") !== -1)) {
      setIsMagnetiqueForm(true)
      setIsFinishBot(true)
    }

    const options = {
      headers: { "Content-Type": "application/json" },
    };

    if (formDataSerialized.reason && formDataSerialized.reason.indexOf("reason_message_erreur") !== -1) {
      endMessageErrorFlow("Merci.")
    }

    if (formDataSerialized.reason && formDataSerialized.reason.indexOf("reason_disponibilite_carte") !== -1) {
      endMessageErrorFlow("Veuillez contacter votre gestionnaire ou vous rapprocher d’une agence Sunu Banque. Merci")
    }

    if (formDataSerialized.reason && formDataSerialized.reason.indexOf("reason_capture") !== -1) {
      endMessageErrorFlow("Veuillez contacter votre gestionnaire ou vous rapprocher d’une agence Sunu Banque. Merci")
    }

    if (formDataSerialized.email){
      let datatosend = {...formDataSerialized, files: standardFiles, standard:true}

      setFinishBotForm(datatosend)
      applyUserRequest(datatosend)
    } else {
      setFinishBotForm(formDataSerialized)
    }


  }

  const checkValidWalletForm = () => {
    setIsValidSubmitForm((
      formWalletForm.firstname.length > 0 &&
      formWalletForm.lastname.length > 0 &&
      formWalletForm.agency.length > 0 &&
      formWalletForm.phone.length > 0 &&
      formWalletForm.email.length > 0 &&
      formWalletForm.accountNumber.length == 11 &&
      formWalletForm.orangeMoneyNumber.length > 0 &&
      formWalletForm.transactionDate.length > 0 &&
      formWalletForm.transactionAmount.length > 0 &&
      formWalletForm.transactionRef.length > 0
    ))
  }

  const checkValidMagnetiqueForm = () => {
    setIsValidSubmitForm((
      formMagnetiqueForm.firstname.length > 0 &&
      formMagnetiqueForm.lastname.length > 0 &&
      formMagnetiqueForm.phone.length > 0 &&
      formMagnetiqueForm.email.length > 0 &&
      formMagnetiqueForm.agency.length > 0 &&
      formMagnetiqueForm.accountNumber.length == 11 &&
      formMagnetiqueForm.typeCard.length > 0 &&
      formMagnetiqueForm.cardNumber.length > 0 &&
      formMagnetiqueForm.transactionDate.length > 0 &&
      formMagnetiqueForm.transactionTime.length > 0 &&
      formMagnetiqueForm.transactionAmount.length > 0 &&
      formMagnetiqueForm.transactionLocation.length > 0
    ))
  }

  const handleValidate = (e, name) => {
    const value = e.target.value
    let label = ""

    if(name === "accountNumber") {
      let myValue = value.replace('_','')

      if (myValue.length !== 11) {
        label = "Le numéro de compte doit comporter 11 chiffres"
      }
    }

    if (value === "") {
      label = "Ce champs est obligatoire"
    }

    setErrorsForm({ ...errorsForm, [name]: label })
  }

  const endMessageErrorFlow = (msg) => {
    cf?.addRobotChatResponse(msg);
    document.querySelectorAll('.animate-in')[0].innerHTML = null
  }

  const endMessage = (params) => {
    xcf?.addRobotChatResponse("Je viens de vous créer le ticket #" + rightdesk.response?.number)
    setTimeout(()=>{
      xcf?.addRobotChatResponse("Un de mes collègues vous contactera si nous avons besoin de plus d'information sinon vous serez automatiquement notifié dès que la demande est traitée.")
    },[1000])
    setTimeout(()=>{
      xcf?.addRobotChatResponse('Merci.');
    },[1500])
    document.querySelectorAll('.animate-in')[0].innerHTML = null
  }
  const applyUserRequest = (dts) => {
    if(document.querySelectorAll('button')[0]) document.querySelectorAll('button')[0].innerText = "Traitement en cours..."

    let answerBot = {
      ...finishBotForm,
    }
    if (isWalletForm) {
      answerBot = {
        ...answerBot,
        formWalletForm,
      }
    }
    if (isMagnetiqueForm) {
      answerBot = {
        ...answerBot,
        formMagnetiqueForm,
      }
    }
    if (dts) {
      cf.addRobotChatResponse('Traitement en cours ...');
      xcf = cf
      answerBot = {
        ...answerBot,
        ...dts,
      }
    }
    // console.log("... Answer Bot", answerBot)

    setSubmited(false)
    setError(null)
    rightdesk.send(answerBot, props.companyData.publickey, props.companyData, props.categories)

  }


  useEffect(()=>{

    if (rightdesk.response?.number){
      //afficher message de success
      // console.log('success')
      setIsWalletForm(false)
      setIsMagnetiqueForm(false)
      setSubmited(true)
      if(document.querySelectorAll('button')[0]) document.querySelectorAll('button')[0].innerText = "Envoyer"

      if (finishBotForm.standard){
        endMessage(rightdesk.response.number)
      }
    }
    if (rightdesk.response?.error) {
      if (document.querySelectorAll('button')[0]) document.querySelectorAll('button')[0].innerText = "Envoyer"
      //afficher message derreur
      // console.log('error')
      setSubmited(false)
      setError(rightdesk.response?.error)
      if (document.querySelectorAll('button')[0]) document.querySelectorAll('button')[0].style.display = "none"

      setTimeout(() => {
        if (document.querySelectorAll('button')[0]) document.querySelectorAll('button')[0].style.display = null
        setError(null)
      }, 3000)
    }
  }, [rightdesk.response])

  React.useEffect(()=>{
    if (props.formData.support_link && props.companyData.publickey){
      rightdesk.setCompany(props.companyData)
    }
  },[props.formData, props.company])
  React.useEffect(()=>{
    setCats(props.categories)
  },[props.categories])
  return (
    <div>
      {isFinishBot && (
        <div className='right-sunu_banque-form-wallet'>
          {isWalletForm && (
            <div>
              <div className="uk-container uk-container-xsmall">
                <div className="right-sunu_banque-form-bot-container">
                  <h3>
                    IDENTIFICATION DU CLIENT
                  </h3>
                  <div>
                    <fieldset className="uk-fieldset">
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Nom *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldWalletChange(e, 'lastname')} onBlur={(e) => handleValidate(e, 'lastname')} value={formWalletForm.lastname} className="uk-input" type="text" placeholder="Entrez votre nom" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.lastname}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Prénom(s) *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldWalletChange(e, 'firstname')} onBlur={(e) => handleValidate(e, 'firstname')} value={formWalletForm.firstname} className="uk-input" type="text" placeholder="Entrez votre prénom(s)" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.firstname}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Contact téléphonique *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldWalletChange(e, 'phone')} onBlur={(e) => handleValidate(e, 'phone')} value={formWalletForm.phone} className="uk-input" type="number" placeholder="Saisissez votre contact" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.phone}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Email *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldWalletChange(e, 'email')} value={formWalletForm.email} value={formWalletForm.email} className="uk-input" type="text" placeholder="Saisissez votre email" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.email}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Agence *</label>
                        <div className="uk-form-controls">
                          <select className="uk-select" onChange={(e) => handleFieldWalletChange(e, 'agency')} onBlur={(e) => handleValidate(e, 'agency')} value={formWalletForm.agency}>
                            <option value="">Veuillez choisir</option>
                            <option>Agence 1</option>
                            <option>Agence 2</option>
                            <option>Agence 3</option>
                            <option>Agence 4</option>
                          </select>
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.agency}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Numéro de compte *</label>
                        <div className="uk-form-controls">
                          <InputMask onChange={(e) => handleFieldWalletChange(e, 'accountNumber')} onBlur={(e) => handleValidate(e, 'accountNumber')} value={formWalletForm.accountNumber} className="uk-input" mask="***********"/>
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.accountNumber}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">N° ORANGE MONEY *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldWalletChange(e, 'orangeMoneyNumber')} onBlur={(e) => handleValidate(e, 'orangeMoneyNumber')} value={formWalletForm.orangeMoneyNumber} className="uk-input" type="number" placeholder="Entrez N° ORANGE MONEY" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.orangeMoneyNumber}</small>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <h3>
                    IDENTIFICATION DE LA TRANSACTION
                  </h3>
                  <div>
                    <fieldset className="uk-fieldset">
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Date de la transaction *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldWalletChange(e, 'transactionDate')} onBlur={(e) => handleValidate(e, 'transactionDate')} value={formWalletForm.transactionDate} className="uk-input" type="date" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.transactionDate}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Montant *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldWalletChange(e, 'transactionAmount')} onBlur={(e) => handleValidate(e, 'transactionAmount')} value={formWalletForm.transactionAmount} className="uk-input" type="number" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.transactionAmount}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Référence * (*la référence est contenue dans le message envoyé par Orange) </label>
                        <div className="uk-form-controls">
                          <InputMask onChange={(e) => handleFieldWalletChange(e, 'transactionRef')} onBlur={(e) => handleValidate(e, 'transactionRef')} value={formWalletForm.transactionRef} className="uk-input" mask="OR-******-******"/>
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.transactionRef}</small>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <h3>
                    PIECES JOINTES (Facultatif)
                  </h3>
                  <fieldset className="uk-fieldset">
                    <div className="uk-margin">
                      {isFileLoading && (
                        <div>
                          <small className="right-sunu_banque-form-success-msg">Fichier(s) en cours de téléchargement...</small>
                        </div>
                      )}
                      <div>
                          <small className="right-sunu_banque-form-success-msg">{successMsgFileUpload}</small>
                        </div>
                      <div className="uk-form-custom">
                        <input onChange={(e) => handleFieldWalletChange(e, 'files')} multiple type="file" />
                        <button class="uk-button uk-button-default" type="button" tabindex="-1">Ajouter des fichiers</button>
                      </div>
                      <div>
                        <small className="right-sunu_banque-form-error-msg">{errorMsgFileUpload}</small>
                      </div>
                    </div>
                    <div className="uk-margin uk-text-center">
                      <button onClick={() => applyUserRequest()} disabled={!isValidSubmitForm} className="uk-button uk-button-default right-sunu_banque-form-bot-form-btn-full">Envoyer</button>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          )}
          {isMagnetiqueForm && (
            <div>
              <div className="uk-container uk-container-xsmall">
                <div className="right-sunu_banque-form-bot-container">
                  <h3>
                    IDENTIFICATION DU PORTEUR DE LA CARTE
                  </h3>
                  <div>
                    <fieldset className="uk-fieldset">
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Nom *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldMagnetiqueChange(e, 'lastname')} onBlur={(e) => handleValidate(e, 'lastname')} value={formMagnetiqueForm.lastname} className="uk-input" type="text" placeholder="Entrez votre nom" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.lastname}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Prénom(s) *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldMagnetiqueChange(e, 'firstname')} onBlur={(e) => handleValidate(e, 'firstname')} value={formMagnetiqueForm.firstname} className="uk-input" type="text" placeholder="Entrez votre prénom(s)" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.firstname}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Contact téléphonique *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldMagnetiqueChange(e, 'phone')} onBlur={(e) => handleValidate(e, 'phone')} value={formMagnetiqueForm.phone} className="uk-input" type="number" placeholder="Saisissez votre contact" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.phone}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Email *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldMagnetiqueChange(e, 'email')} onBlur={(e) => handleValidate(e, 'email')} value={formMagnetiqueForm.email} className="uk-input" type="text" placeholder="Saisissez votre email" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.email}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Agence *</label>
                        <div className="uk-form-controls">
                          <select className="uk-select" onChange={(e) => handleFieldMagnetiqueChange(e, 'agency')} onBlur={(e) => handleValidate(e, 'agency')} value={formMagnetiqueForm.agency}>
                            <option value="">Veuillez choisir</option>
                            <option>Agence 1</option>
                            <option>Agence 2</option>
                            <option>Agence 3</option>
                            <option>Agence 4</option>
                          </select>
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.agency}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Numéro de compte *</label>
                        <div className="uk-form-controls">
                          <InputMask onChange={(e) => handleFieldMagnetiqueChange(e, 'accountNumber')} onBlur={(e) => handleValidate(e, 'accountNumber')} value={formMagnetiqueForm.accountNumber} className="uk-input" mask="***********"/>
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.accountNumber}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Type de carte *</label>
                        <div className="uk-form-controls">
                          <select className="uk-select" onChange={(e) => handleFieldMagnetiqueChange(e, 'typeCard')} onBlur={(e) => handleValidate(e, 'typeCard')} value={formMagnetiqueForm.typeCard}>
                            <option value="">Veuillez choisir</option>
                            <option>GIM</option>
                            <option>VISA CLASSIC</option>
                            <option>VISA GOLD</option>
                          </select>
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.typeCard}</small>
                          </div>
                        </div>
                      </div>
                      {formMagnetiqueForm.typeCard !== "" && (
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Numéro de carte *</label>
                        <div className="uk-form-controls">
                          {(formMagnetiqueForm.typeCard == "" || formMagnetiqueForm.typeCard == "GIM") && (
                            <InputMask onChange={(e) => handleFieldMagnetiqueChange(e, 'cardNumber')} onBlur={(e) => handleValidate(e, 'cardNumber')} value={formMagnetiqueForm.cardNumber} className="uk-input" mask="6276-8599-9999-9999"/>
                          )}
                          {formMagnetiqueForm.typeCard == "VISA CLASSIC" && (
                            <InputMask onChange={(e) => handleFieldMagnetiqueChange(e, 'cardNumber')} onBlur={(e) => handleValidate(e, 'cardNumber')} value={formMagnetiqueForm.cardNumber} className="uk-input" mask="4047-8799-9999-9999" />
                          )}
                          {formMagnetiqueForm.typeCard == "VISA GOLD" && (
                            <InputMask onChange={(e) => handleFieldMagnetiqueChange(e, 'cardNumber')} onBlur={(e) => handleValidate(e, 'cardNumber')} value={formMagnetiqueForm.cardNumber} className="uk-input" mask="4047-8799-9999-9999" />
                          )}
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.cardNumber}</small>
                          </div>
                        </div>
                      </div>
                      )}
                    </fieldset>
                  </div>
                  <h3>
                    IDENTIFICATION DE LA TRANSACTION
                  </h3>
                  <div>
                    <fieldset className="uk-fieldset">
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Date de la transaction *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldMagnetiqueChange(e, 'transactionDate')} onBlur={(e) => handleValidate(e, 'transactionDate')} value={formMagnetiqueForm.transactionDate} className="uk-input" type="date" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.transactionDate}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Heure *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldMagnetiqueChange(e, 'transactionTime')} onBlur={(e) => handleValidate(e, 'transactionTime')} value={formMagnetiqueForm.transactionTime} className="uk-input" type="time" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.transactionTime}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Montant *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldMagnetiqueChange(e, 'transactionAmount')} onBlur={(e) => handleValidate(e, 'transactionAmount')} value={formMagnetiqueForm.transactionAmount} className="uk-input" type="number" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.transactionAmount}</small>
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <label className="uk-form-label" for="form-stacked-text">Lieu/Site internet/Marchand *</label>
                        <div className="uk-form-controls">
                          <input onChange={(e) => handleFieldMagnetiqueChange(e, 'transactionLocation')} onBlur={(e) => handleValidate(e, 'transactionLocation')} value={formMagnetiqueForm.transactionLocation} className="uk-input" type="text" placeholder="Entrez l'information" />
                          <div>
                            <small className="right-sunu_banque-form-error-msg">{errorsForm.transactionLocation}</small>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <h3>
                    PIECES JOINTES (Facultatif)
                  </h3>
                  <fieldset className="uk-fieldset">
                    <div className="uk-margin">
                      {isFileLoading && (
                        <div>
                          <small className="right-sunu_banque-form-success-msg">Fichier(s) en cours de téléchargement...</small>
                        </div>
                      )}
                      <div>
                          <small className="right-sunu_banque-form-success-msg">{successMsgFileUpload}</small>
                        </div>
                      <div className="uk-form-custom">
                        <input onChange={(e) => handleFieldMagnetiqueChange(e, 'files')} multiple type="file" />
                        <button class="uk-button uk-button-default" type="button" tabindex="-1">Ajouter des fichiers</button>
                      </div>
                      <div>
                        <small className="right-sunu_banque-form-error-msg">{errorMsgFileUpload}</small>
                      </div>
                    </div>
                    <div className="uk-margin uk-text-center">
                        <button onClick={() => applyUserRequest()} disabled={!isValidSubmitForm} className="uk-button uk-button-default right-sunu_banque-form-bot-form-btn-full">Envoyer</button>
                      </div>
                  </fieldset>
                </div>
              </div>
            </div>
          )}
          {submited && <div style={{ paddingTop: "40vh", textAlign: "center", paddingLeft: "10%", paddingRight: "10%" }}>
            <h2>Je viens de vous créer le ticket <span style={{ color: "#a00" }}>#{rightdesk.response?.number}</span></h2>
            <p>Un de mes collègues vous contactera si nous avons besoin de plus d'information sinon vous serez automatiquement notifié dès que la demande est traitée.</p>
            <p>Merci.</p>
            <p><button onClick={() => {
              if (window.location.pathname.includes('header')) {
                window.location = "/bot"
              } else window.location = "/"
            }} className="uk-button uk-button-default right-sunu_banque-form-bot-form-btn-full">Retour a l'Accueil</button></p>
          </div>}
          {error && <div className={'rcError'} style={{ padding: 30, textAlign: "center", color: "#a00", marginBottom: 30 }}>
            {error}
          </div>}
        </div>
      )}

      {!isFinishBot && (
        <div className="CForm right-sunu_banque-bot-conversational">
          <div
            ref={elem}
          />
        </div>
      )}
    </div>
  )
}

export default ConversationalFormSunuBanque;
