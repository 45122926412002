import React from 'react';
import './Survey.css';
import Header from "../Header";

const Survey = (props) => {
    React.useEffect(()=>{
        if (!props.formData.survey_link){
            window.location="/"
        }
    },[props.formData])
    return (
        <div className="Survey">
            <div className="right-bdu-survey-container">
                <iframe id="right-bdu-survey-frame"
                        title="SONDAGE"
                        width=""
                        height=""
                        src={props.formData.survey_link}>
                </iframe>
            </div>
        </div>
    );
};

export default Survey;