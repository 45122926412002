import React from 'react';
import './Company.css';
import {Route, Routes} from "react-router-dom";
import Header from "../components/Header";
import Bot from "../components/Bot";
import Survey from "../components/Survey";
import Welcome from "../components/Welcome";
import ConversationalForm from "../sunuBanque/ConversationalForm";
import constants from "../constants";
import { Helmet } from 'react-helmet';

const Company = (props) => {
    const [company, setCompany] = React.useState()
    const [formData, setFormData] = React.useState(undefined)
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(undefined)
    const [categories, setCategories] = React.useState([])

    const getFormData = (data) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "alias": data.subdomain,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(constants.prod.adminApiUrl + "/api/notebook/read", requestOptions)
            .then(response => response.json())
            .then(result => {
                setFormData(result.data)
                setCompany(data)
                getCategoriesData(data)
            })
            .catch(error => setError(error));
    }
    const getCategoriesData = (data) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        // console.log(data)
        fetch(constants.prod.rightDeskUrl + "/api/v1/webform/general?subdomaine="+data.subdomain, requestOptions)
            .then(response => response.json())
            .then(result => {
                setCategories(result.data.auto_priorization_subjects)
                setLoading(false)
            })
            .catch(error => setError(error));
    }
    React.useEffect(() => {
        setLoading(true)
        setError(undefined)
        fetch(constants.prod.accountClientUrl + '/api/v2/checkcompany?tokenkey=AIzaSyDY0kkJiTPVd2U7aTOAwhc9ySH6oHx0IYM&alias=' + props.company)
            .then(r => r.json())
            .then(data => {
                if (data.status === 200) {
                    getFormData(data.data)
                } else {
                    // setError(data.message)
                    setError("No Company Found")
                }
            })
            .catch(error => setError(error));
    }, [props.company])

    return (
        <div className="Company">
            <>
                <Helmet>
                    <title>CX Webform</title>
                    <meta name="description" content={company?.language === "fr"?
                            "Logiciel de gestion d’expérience client propulsé par RightCom":
                            "Customer Experience Management software powered by RightCom"} />
                </Helmet>
            </>
            {loading ? <div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '99vh'}}>
                    {!error && <div className="loader"></div>}
                    {error && <div>{error}</div>}
                </div>
            </div> : <>
                <Routes>
                    <Route exact path={'/'}
                           element={<Welcome company={props.company} companyData={company} formData={formData}/>}/>
                    <Route exact path={'/bot'} element={<>
                        <Header company={props.company} companyData={company} formData={formData}/>
                        {company.subdomain === "sunu" && <ConversationalForm company={props.company} companyData={company} formData={formData} categories={categories}/>}
                    </>}/>
                    <Route exact path={'/bot/not-header'} element={<>
                        <ConversationalForm company={props.company} companyData={company} formData={formData} categories={categories}/>
                    </>}/>
                    <Route exact path={'/survey'} element={<>
                        <Header company={props.company} companyData={company} formData={formData}/>
                        <Survey company={props.company} companyData={company} formData={formData}/>
                    </>}/>
                </Routes>
            </>}
        </div>
    );
};

export default Company;
