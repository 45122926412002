// Import Parts Conversational
import processMonetique from "./parts/process-monetique";
import processTransfer from "./parts/process-transfer";
import processBankingService from "./parts/process-banking-service";
import processCustomerRelationship from "./parts/process-customer-relationship";
import processOthers from "./parts/process-others";
import formStandard from "./parts/form-standard";

export const form_flow = [
    {
        'tag': 'cf-robot-message',
        'type': 'text',
        'name': 'welcome',
        'cf-questions': 'Bienvenue au service client de votre Banque !',
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'category',
        'cf-questions': 'Choisissez la categorie de la requête ?',
        "children": [
            {
                "tag": "option",
                "cf-label": "Monétique",
                "value": "category_monetique",
            },
            {
                "tag": "option",
                "cf-label": "Transfert",
                "value": "category_transfer",
            },
            // {
            //     "tag": "option",
            //     "cf-label": "Service Bancaire",
            //     "value": "category_banking_service",
            // },
            {
                "tag": "option",
                "cf-label": "Opération sur compte",
                "value": "subject_banking_service_account_transaction",
            },
            {
                "tag": "option",
                "cf-label": "Chèque et effets",
                "value": "subject_banking_service_Check",
            },
            {
                "tag": "option",
                "cf-label": "Prêt",
                "value": "subject_banking_service_loan",
            },
            {
                "tag": "option",
                "cf-label": "Assurance",
                "value": "subject_banking_service_insurance",
            },
            {
                "tag": "option",
                "cf-label": "Relation Client",
                "value": "category_customer_relationship",
            },
            {
                "tag": "option",
                "cf-label": "Autres",
                "value": "category_others",
            }
        ]
    },
    ...processMonetique,
    ...processTransfer,
    ...processBankingService,
    ...processCustomerRelationship,
    ...processOthers,
    ...formStandard,
]
