import React from "react";
import axios from "axios"
import sunu_banque_voccabulaire from "./sunu_banque_voccabulaire";
import constants from "../constants";

const useRightDesk = (props) => {
    const [response, setResponse] = React.useState({})
    const [companyData, setCompanyData] = React.useState({})

    const getVocabulary = (key) => {
        let vocabl = sunu_banque_voccabulaire[key]
        return vocabl
    }
    const toNormalForm = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const getCat = (answer, categories) => {
        let ccc
        // if(answer.sub_reason?.length>0){
        //     ccc = categories.filter(c => toNormalForm(c.title.trim().toLowerCase()) === sunu_banque_voccabulaire[answer.sub_reason[0]]['fr'].trim().toLowerCase());
        //     console.log(ccc)
        // } else {
        if (window.location.hostname.includes('localhost')) console.log(answer)
        if (answer.reason?.length > 0) {
            ccc = categories.filter(c => toNormalForm(c.title.trim().toLowerCase()) === toNormalForm(sunu_banque_voccabulaire[answer.reason[0]]['fr'].trim().toLowerCase()));
            if (window.location.hostname.includes('localhost')) console.log(ccc)
            if (ccc.length === 0) {
                if (answer.objet?.length > 0) {
                    ccc = categories.filter(c => toNormalForm(c.title.trim().toLowerCase()) === toNormalForm(sunu_banque_voccabulaire[answer.objet[0]]['fr'].trim().toLowerCase()));
                    if (window.location.hostname.includes('localhost')) console.log(ccc)
                } else {
                    if (answer.category?.length > 0) {
                        ccc = categories.filter(c => toNormalForm(c.title.trim().toLowerCase()) === toNormalForm(sunu_banque_voccabulaire[answer.category[0]]['fr'].trim().toLowerCase()));
                        if (window.location.hostname.includes('localhost')) console.log(ccc)
                    } else {

                    }
                }
            }
        } else {
            if (answer.objet?.length > 0) {
                ccc = categories.filter(c => toNormalForm(c.title.trim().toLowerCase()) === toNormalForm(sunu_banque_voccabulaire[answer.objet[0]]['fr'].trim().toLowerCase()));
                if (window.location.hostname.includes('localhost')) console.log(ccc)
            } else {
                if (answer.category?.length > 0) {
                    ccc = categories.filter(c => toNormalForm(c.title.trim().toLowerCase()) === toNormalForm(sunu_banque_voccabulaire[answer.category[0]]['fr'].trim().toLowerCase()));
                    if (window.location.hostname.includes('localhost')) console.log(ccc)
                } else {

                }
            }
        }
        // }
        if (window.location.hostname.includes('localhost')) console.log(ccc[0])
        if (ccc.length === 0) {
            ccc = categories.filter(c => toNormalForm(c.title.trim().toLowerCase()) === toNormalForm(sunu_banque_voccabulaire["category_others"]['fr'].trim().toLowerCase()));
        }
        
        return ccc[0]
    }
    const assignedAgent = (cid, company) => {
        const agents = {
            "category_monetique": {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "bet@sunu_banqueci.com",
                "firstname": "Service ",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": false,
                "is_owner": false,
                "language": "fr",
                "lastname": "Monetique",
                "phone": "",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "M",
                "status": 1,
                "timezone": "GMT+00:00",
                "user_id": "48f43560-5710-11ec-a53e-1fe8c98462c3"
            },
            "category_banking_service": {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "mkebe@sunu_banqueci.com",
                "firstname": "Mohamed ",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": false,
                "is_owner": false,
                "language": "fr",
                "lastname": "KEBE",
                "phone": "0140138297",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "M",
                "status": 2,
                "timezone": "GMT+00:00",
                "user_id": "b3b00a20-570e-11ec-b4bc-9b85b3402643"
            },
        }
        const a1 = {
            "address": "",
            "city": "",
            "country": "CD",
            "email": "a.keita@tmb.cd",
            "firstname": "KEITA ",
            "full_rights": [
                {
                    "code": "USR",
                    "group": "Simple User",
                    "name": "RightDesk Simple User"
                }
            ],
            "image": "",
            "is_admin": false,
            "is_owner": false,
            "language": "fr",
            "lastname": "Aboudramane",
            "phone": "+243832611407",
            "rights_codes": [
                "USR"
            ],
            "sexe": "M",
            "status": 1,
            "timezone": "GMT+01:00",
            "user_id": "818f55f0-10df-11ee-b024-f793c37e2fb1"
        }
        const branch = {
            "Agence 1": a1,
            "Agence 2": a1,
            "Agence 3": a1,
            "Agence 4": a1
        }
        const pool = [
            {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "ccodo@sunu_banqueci.com",
                "firstname": "Colombe",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": true,
                "is_owner": false,
                "language": "fr",
                "lastname": "CODO",
                "phone": "0799818383",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "F",
                "status": 1,
                "timezone": "GMT+00:00",
                "user_id": "08dd75a0-570f-11ec-9f13-4f919a90b7cf"
            },
            {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "matji@sunu_banqueci.com",
                "firstname": "Mohamed ",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": false,
                "is_owner": false,
                "language": "fr",
                "lastname": "ATJI",
                "phone": "0787574210",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "M",
                "status": 2,
                "timezone": "GMT+00:00",
                "user_id": "e2886bc0-570f-11ec-9f13-4f919a90b7cf"
            },
            {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "ycoulibaly@sunu_banqueci.com",
                "firstname": "Yah Sita",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": false,
                "is_owner": false,
                "language": "fr",
                "lastname": "COULIBALY",
                "phone": "0747045542",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "F",
                "status": 2,
                "timezone": "GMT+00:00",
                "user_id": "76a518e0-570f-11ec-b4bc-9b85b3402643"
            },
            {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "nkonate@sunu_banqueci.com",
                "firstname": "Nadège",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": true,
                "is_owner": true,
                "language": "fr",
                "lastname": "KONATE",
                "phone": "+ 22509708359",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "F",
                "status": 1,
                "timezone": "",
                "user_id": "f8570460-0d4c-11eb-b145-37a7435e2eec"
            },
            {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "igueu@sunu_banqueci.com",
                "firstname": "Ines",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": false,
                "is_owner": false,
                "language": "fr",
                "lastname": "GUEU",
                "phone": "0758224553",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "F",
                "status": 2,
                "timezone": "GMT+00:00",
                "user_id": "6831e110-643f-11ed-88ba-6f8dafb19ee2"
            }
        ]
        if (company?.subdomain === "sunu_banque") {
            return agents[cid] || pool[Math.floor(Math.random() * pool.length)]
        } else {
            return {
                "firstname": "Thomas C",
                "lastname": "BOKO",
                "phone": "22997409633",
                "sexe": "M",
                "language": "fr",
                "lang_dir": "ltr",
                "fullname": "Thomas C BOKO",
                "email": "thomas@right-com.com",
                "userid": "6a2e4c70-0f67-11ec-88ea-fbb3eb33f232",
                "publickey": "POKB19302SOK97W",
                "alias": "MB1O5HSOK97W",
                "firstlogin": 1,
                "subdomain": "rightcom",
                "company": "RightCom Technologies",
                "is_admin": true,
                "session_id": "hidyFfAAkWDLARLBmjSkw0kEtdP6Ax1aFty6AoWFtssYK",
                "full_rights": [
                    {
                        "code": "USA",
                        "group": "Simple User",
                        "name": "RightCare Simple User"
                    },
                    {
                        "code": "MAN",
                        "group": "Administration",
                        "name": "RightCare Manager"
                    }
                ],
                "rights_codes": [
                    "USA",
                    "MAN"
                ],
                "apisid": "1wEZXyESJfMXZbi6AFMABWeeq7P8tRswo9PjlRF2",
                "checked": true
            }
        }
    }
    const assignedAgentByBranch = (agence, company) => {
        console.log(agence)
        const a1 = {
            "address": "",
            "city": "",
            "country": "CD",
            "email": "a.keita@tmb.cd",
            "firstname": "KEITA ",
            "full_rights": [
                {
                    "code": "USR",
                    "group": "Simple User",
                    "name": "RightDesk Simple User"
                }
            ],
            "image": "",
            "is_admin": false,
            "is_owner": false,
            "language": "fr",
            "lastname": "Aboudramane",
            "phone": "+243832611407",
            "rights_codes": [
                "USR"
            ],
            "sexe": "M",
            "status": 1,
            "timezone": "GMT+01:00",
            "user_id": "818f55f0-10df-11ee-b024-f793c37e2fb1"
        }
        const branch = {
            "Agence 1": a1,
            "Agence 2": a1,
            "Agence 3": a1,
            "Agence 4": a1
        }

        const pool = [
            {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "ccodo@sunu_banqueci.com",
                "firstname": "Colombe",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": true,
                "is_owner": false,
                "language": "fr",
                "lastname": "CODO",
                "phone": "0799818383",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "F",
                "status": 1,
                "timezone": "GMT+00:00",
                "user_id": "08dd75a0-570f-11ec-9f13-4f919a90b7cf"
            },
            {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "matji@sunu_banqueci.com",
                "firstname": "Mohamed ",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": false,
                "is_owner": false,
                "language": "fr",
                "lastname": "ATJI",
                "phone": "0787574210",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "M",
                "status": 2,
                "timezone": "GMT+00:00",
                "user_id": "e2886bc0-570f-11ec-9f13-4f919a90b7cf"
            },
            {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "ycoulibaly@sunu_banqueci.com",
                "firstname": "Yah Sita",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": false,
                "is_owner": false,
                "language": "fr",
                "lastname": "COULIBALY",
                "phone": "0747045542",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "F",
                "status": 2,
                "timezone": "GMT+00:00",
                "user_id": "76a518e0-570f-11ec-b4bc-9b85b3402643"
            },
            {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "nkonate@sunu_banqueci.com",
                "firstname": "Nadège",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": true,
                "is_owner": true,
                "language": "fr",
                "lastname": "KONATE",
                "phone": "+ 22509708359",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "F",
                "status": 1,
                "timezone": "",
                "user_id": "f8570460-0d4c-11eb-b145-37a7435e2eec"
            },
            {
                "address": "",
                "city": "",
                "country": "CI",
                "email": "igueu@sunu_banqueci.com",
                "firstname": "Ines",
                "full_rights": [
                    {
                        "code": "USR",
                        "group": "Simple User",
                        "name": "RightDesk Simple User"
                    }
                ],
                "image": "",
                "is_admin": false,
                "is_owner": false,
                "language": "fr",
                "lastname": "GUEU",
                "phone": "0758224553",
                "rights_codes": [
                    "USR"
                ],
                "sexe": "F",
                "status": 2,
                "timezone": "GMT+00:00",
                "user_id": "6831e110-643f-11ed-88ba-6f8dafb19ee2"
            }
        ]
        if (company?.subdomain === "demo") {
            return branch[agence] || pool[Math.floor(Math.random() * pool.length)]
        } else {
            return {
                "firstname": "Thomas C",
                "lastname": "BOKO",
                "phone": "22997409633",
                "sexe": "M",
                "language": "fr",
                "lang_dir": "ltr",
                "fullname": "Thomas C BOKO",
                "email": "thomas@right-com.com",
                "userid": "6a2e4c70-0f67-11ec-88ea-fbb3eb33f232",
                "publickey": "POKB19302SOK97W",
                "alias": "MB1O5HSOK97W",
                "firstlogin": 1,
                "subdomain": "rightcom",
                "company": "RightCom Technologies",
                "is_admin": true,
                "session_id": "hidyFfAAkWDLARLBmjSkw0kEtdP6Ax1aFty6AoWFtssYK",
                "full_rights": [
                    {
                        "code": "USA",
                        "group": "Simple User",
                        "name": "RightCare Simple User"
                    },
                    {
                        "code": "MAN",
                        "group": "Administration",
                        "name": "RightCare Manager"
                    }
                ],
                "rights_codes": [
                    "USA",
                    "MAN"
                ],
                "apisid": "1wEZXyESJfMXZbi6AFMABWeeq7P8tRswo9PjlRF2",
                "checked": true
            }
        }
    }
    const send = (answers, publickey, company, categories) => {
        console.log(answers, publickey, company, categories)
        setCompanyData({...companyData, ...company})
        // setTimeout(() => {
        //     setResponse({xnumber: 23423432, error: "Une erreure est survenue. Veuiller ressayer."})
        // }, 5000)
        const agence = answers.clientAgence || answers.formMagnetiqueForm?.agency || answers.formWalletForm?.agency
        console.log(answers.clientAgence, answers.formMagnetiqueForm?.agency, answers.formWalletForm?.agency)

        let cat = getCat(answers, categories)
        let data = {
            "language": "fr",
            "publickey": companyData?.publickey || company?.publickey || publickey,
            "company_information": {
                "name": companyData?.name || company?.name,
                "logo": companyData?.logo || company?.logo,
                "countryname": companyData?.country?.countryname || company?.country?.countryname,
                "address": companyData?.companyAdress || company?.companyAdress
            },
            "ticket_information": {
                "message": answers.comment || answers.comment || "",

                "subject": cat?.title,
                "subject_id": cat?.id,
                "cat_parents_ids": cat?.all_parent_id,
                "sla": cat?.sla,
                "priority": cat?.priority,

                "category": {
                    "label": "Technical"
                },
                "custom_ticket_fields": [],
                "status": {
                    "name": "New",
                    "label": "Ticket without agent assign"
                },
                "assigned_agent": assignedAgentByBranch(agence, company),
                "created_by": {
                    "agent_id": "ffffffff-ffff-ffff-ffff-ffffffffffff",
                    "first_name": "Right",
                    "last_name": "Bot",
                    "email": "rightcare@right-com.com",
                    "telephone": "+22966021852"
                },
                "closed": false,
            }
        };
        if (answers["category"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Categorie ",
                "label_fr": "Categorie ",
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.category[0])["fr"]
            })
        }
        if (answers["objet"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Objet ",
                "label_fr": "Objet ",
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.objet[0])["fr"]
            })
        }
        if (answers["reason"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Raison ",
                "label_fr": "Raison ",
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.reason[0])["fr"]
            })
        }
        if (answers["sub_reason"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Raison ",
                "label_fr": "Raison ",
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason[0])["fr"]
            })
        }
        if (answers["sub_reason_sunu_banqueci_orange"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_sunu_banqueci_orange[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_sunu_banqueci_orange[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_sunu_banqueci_orange[0])["fr"]
            })
        }
        if (answers["sub_reason_orange_sunu_banqueci"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_orange_sunu_banqueci[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_orange_sunu_banqueci[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_orange_sunu_banqueci[0])["fr"]
            })
        }
        if (answers["sub_reason_tpe"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_tpe[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_tpe[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_tpe[0])["fr"]
            })
        }
        if (answers["sub_reason_en_ligne"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_en_ligne[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_en_ligne[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_en_ligne[0])["fr"]
            })
        }
        if (answers["sub_reason_retrait"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_retrait[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_retrait[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_retrait[0])["fr"]
            })
        }
        if (answers["sub_reason_mise_a_disposition"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_mise_a_disposition[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_mise_a_disposition[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_mise_a_disposition[0])["fr"]
            })
        }
        if (answers["sub_reason_virement"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_virement[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_virement[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_virement[0])["fr"]
            })
        }
        if (answers["sub_reason_bank_confrere"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_bank_confrere[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_bank_confrere[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_bank_confrere[0])["fr"]
            })
        }
        if (answers["sub_reason_nom_bank_confrere"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_nom_bank_confrere[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_nom_bank_confrere[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_nom_bank_confrere[0])["fr"]
            })
        }
        if (answers["sub_reason_sunu_banqueci"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_sunu_banqueci[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_sunu_banqueci[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_sunu_banqueci[0])["fr"]
            })
        }
        if (answers["sub_reason_agence_sunu_banqueci"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_agence_sunu_banqueci[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_agence_sunu_banqueci[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_agence_sunu_banqueci[0])["fr"]
            })
        }
        if (answers["sub_reason_raison_bille_avale"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_raison_bille_avale[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_raison_bille_avale[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_raison_bille_avale[0])["fr"]
            })
        }
        if (answers["sub_reason_raison_cache_non_livre"]) {
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": getVocabulary(answers.sub_reason_raison_cache_non_livre[0])["en"],
                "label_fr": getVocabulary(answers.sub_reason_raison_cache_non_livre[0])["fr"],
                "type": "Options",
                "mandatory": true,
                "value": getVocabulary(answers.sub_reason_raison_cache_non_livre[0])["fr"]
            })
        }

        if (answers.formWalletForm) {
            // console.log('wallet form customer infos')

            if (answers.formWalletForm.files) {
                data["ticket_information"]["files"] = answers.formWalletForm.files
            }
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Branche",
                "label_fr": "Agence",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formWalletForm.agency
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Account Number",
                "label_fr": "Numero de compte",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formWalletForm.accountNumber
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Orange Money Number",
                "label_fr": "Numero Orange Money",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formWalletForm.orangeMoneyNumber
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Transaction Date",
                "label_fr": "Date Transaction",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formWalletForm.transactionDate
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Transaction Amount",
                "label_fr": "Montant de la Transaction",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formWalletForm.transactionAmount
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Transaction Reference",
                "label_fr": "Reference de la Transaction",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formWalletForm.transactionRef
            })

            data["ticket_information"]["customer_information"] = [
                {
                    "type": "text",
                    "name": "First name",
                    "value": answers.formWalletForm.firstname
                },
                {
                    "type": "text",
                    "name": "Last name",
                    "value": answers.formWalletForm.lastname
                },
                {
                    "type": "email",
                    "name": "Email Address",
                    "value": answers.formWalletForm.email
                },
                {
                    "type": "number",
                    "name": "Telephone",
                    "value": answers.formWalletForm.phone
                }
            ]

        }
        if (answers.formMagnetiqueForm) {
            // console.log('magnetic form customer infos')

            if (answers.formMagnetiqueForm.files) {
                data["ticket_information"]["files"] = answers.formMagnetiqueForm.files
            }
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Branche",
                "label_fr": "Agence",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formMagnetiqueForm.agency
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Account Number",
                "label_fr": "Numero de compte",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formMagnetiqueForm.accountNumber
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Type Of Card",
                "label_fr": "Type De Carte",
                "type": "Options",
                "mandatory": true,
                "value": answers.formMagnetiqueForm.typeCard
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Card Number",
                "label_fr": "Numero Carte",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formMagnetiqueForm.cardNumber
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Transaction Date",
                "label_fr": "Date Transaction",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formMagnetiqueForm.transactionDate
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Transaction Time",
                "label_fr": "Heure Transaction",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formMagnetiqueForm.transactionTime
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Transaction Amount",
                "label_fr": "Montant de la Transaction",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formMagnetiqueForm.transactionAmount
            })
            data["ticket_information"]["custom_ticket_fields"].push({
                "label_en": "Transaction Location",
                "label_fr": "Lieu de la Transaction",
                "type": "Open Text",
                "mandatory": true,
                "value": answers.formMagnetiqueForm.transactionLocation
            })

            data["ticket_information"]["customer_information"] = [
                {
                    "type": "text",
                    "name": "First name",
                    "value": answers.formMagnetiqueForm.firstname
                },
                {
                    "type": "text",
                    "name": "Last name",
                    "value": answers.formMagnetiqueForm.lastname
                },
                {
                    "type": "email",
                    "name": "Email Address",
                    "value": answers.formMagnetiqueForm.email
                },
                {
                    "type": "number",
                    "name": "Telephone",
                    "value": answers.formMagnetiqueForm.phone
                }
            ]

        }
        if (answers.standard) {
            // console.log('standard form customer infos')
            if (answers.files) {
                data["ticket_information"]["files"] = answers.files
            }
            if (answers.accountNumber) {
                data["ticket_information"]["custom_ticket_fields"].push({
                    "label_en": "Numéro de compte",
                    "label_fr": "Numéro de compte",
                    "type": "Open Text",
                    "mandatory": true,
                    "value": answers.accountNumber
                })
            }
            if (answers.clientNumber) {
                data["ticket_information"]["custom_ticket_fields"].push({
                    "label_en": "Numéro client",
                    "label_fr": "Numéro client",
                    "type": "Open Text",
                    "mandatory": true,
                    "value": answers.clientNumber
                })
            }
            if (answers.dateTraitement) {
                data["ticket_information"]["custom_ticket_fields"].push({
                    "label_en": "Date de la demande",
                    "label_fr": "Date de la demande",
                    "type": "Open Text",
                    "mandatory": true,
                    "value": answers.dateTraitement
                })
            }
            if (answers.clientAgence) {
                data["ticket_information"]["custom_ticket_fields"].push({
                    "label_en": "Agence",
                    "label_fr": "Agence",
                    "type": "Open Text",
                    "mandatory": true,
                    "value": answers.clientAgence
                })
            }
            if (answers.montant) {
                data["ticket_information"]["custom_ticket_fields"].push({
                    "label_en": "Montant",
                    "label_fr": "Montant",
                    "type": "Open Text",
                    "mandatory": true,
                    "value": answers.montant
                })
            }
            if (answers.clientTypeContrat) {
                data["ticket_information"]["custom_ticket_fields"].push({
                    "label_en": "Type de contrat",
                    "label_fr": "Type de contrat",
                    "type": "Open Text",
                    "mandatory": true,
                    "value": answers.clientTypeContrat
                })
            }
            if (answers.numeroCheque) {
                data["ticket_information"]["custom_ticket_fields"].push({
                    "label_en": "Numéro de chèque",
                    "label_fr": "Numéro de chèque",
                    "type": "Open Text",
                    "mandatory": true,
                    "value": answers.numeroCheque
                })
            }
            if (answers.details_de_la_reclamation) {
                data["ticket_information"]["custom_ticket_fields"].push({
                    "label_en": "(Date de l’opération, informations complémentaires…)",
                    "label_fr": "(Date de l’opération, informations complémentaires…)",
                    "type": "Open Text",
                    "mandatory": true,
                    "value": answers.details_de_la_reclamation
                })
            }

            if (answers.dateRemise) {
                data["ticket_information"]["custom_ticket_fields"].push({
                    "label_en": "La date de remise",
                    "label_fr": "La date de remise",
                    "type": "Open Text",
                    "mandatory": true,
                    "value": answers.dateRemise
                })
            }
            if (answers.clientCommentaire) {
                data["ticket_information"]["custom_ticket_fields"].push({
                    "label_en": "Commentaire",
                    "label_fr": "Commentaire",
                    "type": "Open Text",
                    "mandatory": true,
                    "value": answers.clientCommentaire
                })
            }


            data["ticket_information"]["customer_information"] = [
                {
                    "type": "text",
                    "name": "First name",
                    "value": answers.firstname
                },
                {
                    "type": "text",
                    "name": "Last name",
                    "value": answers.lastname
                },
                {
                    "type": "email",
                    "name": "Email Address",
                    "value": answers.email
                },
                {
                    "type": "number",
                    "name": "Telephone",
                    "value": answers.phone
                }
            ]

        }

        const config = {
            method: 'post',
            url: constants.prod.rightDeskUrl + '/api/v1/webform/ticket',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        if (window.location.hostname.includes('localhost')) {
            console.log(data)
            axios(config)
            .then(function (response) {
                // console.log(response)
                setResponse(response.data.data);
            })
            .catch(function (error) {
                // console.log(error)
            });
        } else {
            axios(config)
            .then(function (response) {
                // console.log(response)
                setResponse(response.data.data);
            })
            .catch(function (error) {
                // console.log(error)
            });
        }


    }
    const setCompany = (company) => {
        // console.log(company)
        setCompanyData(company)
    }
    return {send, response, setResponse, setCompany}
}

export default useRightDesk
