export default [
    { 
        'tag': 'select',
        'type': 'text',
        'name': 'objet',
        "cf-conditional-category": "category_monetique",
        'cf-questions': "Choisissez l'objet de la requête ",
        "children": [
            {
                "tag": "option",
                "cf-label": "Débit à Tord",
                "value": "subject_debit_a_tord",
            },
            {
                "tag": "option",
                "cf-label": "Autres",
                "value": "subject_autres_objet",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_autres_objet",
        'cf-questions': "Choisissez le motif de la requête",
        "children": [
            {
                "tag": "option",
                "cf-label": "Carte capturée",
                "value": "reason_capture",
            },
            {
                "tag": "option",
                "cf-label": "Disponibilité de la carte",
                "value": "reason_disponibilite_carte",
            },
            {
                "tag": "option",
                "cf-label": "Messages erreur",
                "value": "reason_message_erreur",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'sub_reason',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_autres_objet",
        "cf-conditional-reason": "reason_message_erreur",
        'cf-questions': "Choisissez le message svp",
        "children": [
            {
                "tag": "option",
                "cf-label": "Carte non livrée",
                "value": "sub_reason_Carte_non_livrée",
            },
            {
                "tag": "option",
                "cf-label": "Fraudulent, Pick up",
                "value": "sub_reason_Fraudulent_Pick_up",
            },
            {
                "tag": "option",
                "cf-label": "Code PIN expiré",
                "value": "sub_reason_Code_PIN_expire",
            },
            {
                "tag": "option",
                "cf-label": "Dépassement de plafond",
                "value": "sub_reason_Dépassement_de_plafond",
            },
            {
                "tag": "option",
                "cf-label": "Émetteur indisponible",
                "value": "sub_reason_Emetteur_indisponible",
            },
            {
                "tag": "option",
                "cf-label": "Fond insuffisant",
                "value": "sub_reason_Fond_insuffisant",
            },
            {
                "tag": "option",
                "cf-label": "Fond GAB insuffisant",
                "value": "sub_reason_Fond_GAB_insuffisant",
            },
            {
                "tag": "option",
                "cf-label": "A ne pas honorer",
                "value": "sub_reason_A_ne_pas_honorer",
            },
        ]
    },
    {
        'tag': 'cf-robot-message',
        'type': 'text',
        'name': 'sub_reason_show_message_erreur',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_autres_objet",
        "cf-conditional-reason": "reason_message_erreur",
        "cf-conditional-sub_reason": "sub_reason_Carte_non_livrée",
        'cf-questions': 'Votre carte n’a pas été activée \nContactez votre agence pour son activation.',
    },
    {
        'tag': 'cf-robot-message',
        'type': 'text',
        'name': 'sub_reason_show_message_erreur',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_autres_objet",
        "cf-conditional-reason": "reason_message_erreur",
        "cf-conditional-sub_reason": "sub_reason_Fraudulent_Pick_up",
        'cf-questions': 'Votre carte a été désactivée \nMerci de contacter votre agence.',
    },
    {
        'tag': 'cf-robot-message',
        'type': 'text',
        'name': 'sub_reason_show_message_erreur',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_autres_objet",
        "cf-conditional-reason": "reason_message_erreur",
        "cf-conditional-sub_reason": "sub_reason_Code_PIN_expire",
        'cf-questions': "Suite à un recalcul après 3h, le CODE PIN a expiré. \nContactez votre agence pour reprendre l'action.",
    },
    {
        'tag': 'cf-robot-message',
        'type': 'text',
        'name': 'sub_reason_show_message_erreur',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_autres_objet",
        "cf-conditional-reason": "reason_message_erreur",
        "cf-conditional-sub_reason": "sub_reason_Dépassement_de_plafond",
        'cf-questions': "Le plafond de la carte est atteint.",
    },
    {
        'tag': 'cf-robot-message',
        'type': 'text',
        'name': 'sub_reason_show_message_erreur',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_autres_objet",
        "cf-conditional-reason": "reason_message_erreur",
        "cf-conditional-sub_reason": "sub_reason_Emetteur_indisponible",
        'cf-questions': "Il y a un problème de communication réseau entre le GAB et le serveur de paiement électronique. \nVeuillez réessayer plus tard ou essayer un autre guichet automatique. Merci",
    },
    {
        'tag': 'cf-robot-message',
        'type': 'text',
        'name': 'sub_reason_show_message_erreur',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_autres_objet",
        "cf-conditional-reason": "reason_message_erreur",
        "cf-conditional-sub_reason": "sub_reason_Fond_insuffisant",
        'cf-questions': "Le montant demandé par le client est supérieur au solde.",
    },
    {
        'tag': 'cf-robot-message',
        'type': 'text',
        'name': 'sub_reason_show_message_erreur',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_autres_objet",
        "cf-conditional-reason": "reason_message_erreur",
        "cf-conditional-sub_reason": "sub_reason_Fond_GAB_insuffisant",
        'cf-questions': "Le GAB n’a pas le montant indiqué.",
    },
    {
        'tag': 'cf-robot-message',
        'type': 'text',
        'name': 'sub_reason_show_message_erreur',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_autres_objet",
        "cf-conditional-reason": "reason_message_erreur",
        "cf-conditional-sub_reason": "sub_reason_A_ne_pas_honorer",
        'cf-questions': "Le client a une opposition ou est en interdit de chéquier. Veuillez contactez votre agence Sunu Banque. Merci",
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'reason',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_debit_a_tord",
        'cf-questions': "Choisissez le motif de la requête",
        "children": [
            {
                "tag": "option",
                "cf-label": "Bank to wallet",
                "value": "reason_bank_to_wallet",
            },
            {
                "tag": "option",
                "cf-label": "Guichet Automatique (GAB)",
                "value": "reason_gab",
            },
            {
                "tag": "option",
                "cf-label": "Achat",
                "value": "reason_achat",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'sub_reason',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_debit_a_tord",
        "cf-conditional-reason": "reason_bank_to_wallet",
        'cf-questions': "Choisissez le sous motif de la requête",
        "children": [
            {
                "tag": "option",
                "cf-label": "Sunu Banque vers Orange Money",
                "value": "sub_reason_sunu_banqueci_orange", // Charger le formulaire - Bank Wallet
            },
            {
                "tag": "option",
                "cf-label": "Orange Money vers Sunu Banque",
                "value": "sub_reason_orange_sunu_banqueci", // Charger le formulaire - Bank Wallet
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'sub_reason',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_debit_a_tord",
        "cf-conditional-reason": "reason_achat",
        'cf-questions': "Choisissez le sous motif de la requête",
        "children": [
            {
                "tag": "option",
                "cf-label": "TPE",
                "value": "sub_reason_tpe", // Charger le formulaire - Carte Magnétique
            },
            {
                "tag": "option",
                "cf-label": "En ligne",
                "value": "sub_reason_en_ligne", // Charger le formulaire - Carte Magnétique
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'sub_reason',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_debit_a_tord",
        "cf-conditional-reason": "reason_gab",
        'cf-questions': "Choisissez le sous motif de la requête",
        "children": [
            {
                "tag": "option",
                "cf-label": "Retrait",
                "value": "sub_reason_retrait",
            },
            {
                "tag": "option",
                "cf-label": "Mise à disposition",
                "value": "sub_reason_mise_a_disposition",
            },
            { 
                "tag": "option",
                "cf-label": "Virement",
                "value": "sub_reason_virement",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'sub_reason_bank',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_debit_a_tord",
        "cf-conditional-reason": "reason_gab",
        "cf-conditional-sub_reason": "sub_reason_retrait",
        'cf-questions': "Choisissez la banque",
        "children": [
            {
                "tag": "option",
                "cf-label": "Confrère",
                "value": "sub_reason_bank_confrere",
            },
            {
                "tag": "option",
                "cf-label": "Sunu Banque",
                "value": "sub_reason_sunu_banqueci",
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'sub_reason',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_debit_a_tord",
        "cf-conditional-reason": "reason_gab",
        "cf-conditional-sub_reason": "sub_reason_retrait",
        "cf-conditional-sub_reason_bank": "sub_reason_bank_confrere",
        'cf-questions': "Choisissez l'incident",
        "children": [
            {
                "tag": "option",
                "cf-label": "Billet Avalé",
                "value": "sub_reason_raison_bille_avale", // Charger le formulaire - Carte Magnétique
            },
            {
                "tag": "option",
                "cf-label": "Cash non livré",
                "value": "sub_reason_raison_cache_non_livre", // Charger le formulaire - Carte Magnétique
            },
        ]
    },
    {
        'tag': 'select',
        'type': 'text',
        'name': 'sub_reason',
        "cf-conditional-category": "category_monetique",
        "cf-conditional-objet": "subject_debit_a_tord",
        "cf-conditional-reason": "reason_gab",
        "cf-conditional-sub_reason": "sub_reason_retrait",
        "cf-conditional-sub_reason_bank": "sub_reason_sunu_banqueci",
        'cf-questions': "Choisissez l'incident",
        "children": [
            {
                "tag": "option",
                "cf-label": "Billet Avalé",
                "value": "sub_reason_raison_bille_avale", // Charger le formulaire - Carte Magnétique
            },
            {
                "tag": "option",
                "cf-label": "Cash non livré",
                "value": "sub_reason_raison_cache_non_livre", // Charger le formulaire - Carte Magnétique
            },
        ]
    },
]